import React from 'react'
import Header from './Header'
import { Outlet } from 'react-router-dom'
import { DesignProvider } from 'context/design.js'
import { AdminLayoutProvider } from 'context/admin-layout'

function AdminLayout() {
  return (
    <AdminLayoutProvider>
      <DesignProvider>
        <div className='tw-container xl:tw-max-w-screen-xl tw-mx-auto tw-p-7'>
          <Header />
          <Outlet />
        </div>
      </DesignProvider>
    </AdminLayoutProvider>
  )
}

export default AdminLayout
