import ApplePass from 'components/apple-pass'
import GooglePass from 'components/google-pass'
import { useAuth } from 'context/auth'
import { useDesign } from 'context/design'
import { PASS_VIEW } from 'enums'
import { ACCOUNT_TYPE } from 'enums/user'
import { useFormikContext } from 'formik'
import EyeIcon from 'icons/eye'
import React, { useState } from 'react'

function PassPreview({ children, view, values }) {
  const [showPass, setShowPass] = useState(false)
  const formik = useFormikContext()
  const { currentUser } = useAuth()
  const { design, loading } = useDesign()

  let currentDesign = {}
  const passType = view || currentUser?.passType

  const getFullName = () => {
    let name = formik.values.firstName + ' ' + formik.values.lastName
    return name
  }

  const getDesign = (values) => ({
    backgroundColor: values.backgroundColor,
    logoImage: values.logoImage,
    stripImage: values.stripImage,
    fullName: values.fullName,
    points: values.points,
    passCode: values.passCode || 'pa******',
  })

  if (values) currentDesign = getDesign(values)
  else if (formik?.values)
    currentDesign = getDesign({ ...formik.values, fullName: getFullName() })
  else {
    currentDesign = {
      backgroundColor: design?.backgroundColor || 'rgb(56, 41, 124)',
      logoImage: design?.logoImage,
      stripImage: design?.stripImage,
      fullName: currentUser.fullName,
    }
  }

  return (
    !loading && (
      <div style={{ width: '330px' }}>
        <div
          onClick={() => setShowPass(!showPass)}
          className='tw-flex tw-mb-2 md:tw-hidden tw-justify-center'
        >
          <div className='tw-border tw-border-black tw-py-[8px] tw-px-[20px] tw-rounded-[4px] tw-flex tw-items-center tw-gap-[7px] tw-cursor-pointer tw-text-[15px]'>
            <EyeIcon />
            View card
          </div>
        </div>
        {/* design={values || formik?.values || currentDesign} */}
        <div className={showPass ? 'tw-block' : 'tw-hidden md:tw-block'}>
          {passType == PASS_VIEW.APPLE ? (
            <ApplePass design={currentDesign} />
          ) : (
            <GooglePass design={currentDesign} />
          )}

          {children}
        </div>
      </div>
    )
  )
}

export default PassPreview
