export const PLANS = {
  FREE: 'free',
  BASIC: 'basic',
  FULL_ACCESS: 'fullAccess',
  // STARTER: 'starter',
  // BASIC: 'basic',
  // BUSINESS: 'business',
  // ENTERPRISE: 'enterprise',
  // CUSTOM: 'custom',
}

export const SUBSCRIPTION_PERIODS = {
  MONTHLY: 'monthly',
  ANNUALLY: 'annually',
}

const Free = {
  heading: 'Free',
  services: [
    '5 Customer cards',
    '1 Design Template',
    '2-Push Notification Monthly',
    'Unlimited Redemption',
    'Email Support',
  ],
  plan: PLANS.FREE,
  // plan: PLANS.FREE,
  // cards: 20,
  // trees: 1,
  // designs: 1,
  // admins: 1,
  // personalBranding: false,
  // bulkUpload: false,
  // cardPermission: false,
  // onBoarding: false,
  // support: false,
  // customerServiceManager: false,
  // whiteLabel: false,
  // hrIntegration: false,
  // amount: 1440,
  // VAT: 216,
  // afterDiscount: 993.6,
}
const Basic = {
  heading: 'Basic',
  // previousPlan: '',
  services: [
    '5 Design template',
    '100 Customer cards',
    '50-Push Notification Monthly',
    '5 Team Members',
    '5 Admin Access',
    'Unlimited Redemption',
    'Download PDF',
    'Public APIs',
    '24/7 Support',
  ],
  plan: PLANS.BASIC,
  // plan: PLANS.BASIC,
  // cards: 100,
  // trees: 20,
  // designs: 'any',
  // admins: 2,
  // personalBranding: true,
  // bulkUpload: true,
  // cardPermission: false,
  // onBoarding: false,
  // support: false,
  // customerServiceManager: false,
  // whiteLabel: false,
  // hrIntegration: false,
  // amount: 4800,
  // VAT: 720,
  // afterDiscount: 3312,
}
const Full_Access = {
  heading: 'Full Access',
  // previousPlan: '',
  services: [
    'Unlimited Designs',
    'Unlimited Customers',
    'Unlimited Push Notifications',
    'Unlimited Team Members',
    'Unlimited Admin Access',
    'Unlimited Redemption',
    'Public APIs',
    'Download PDF',
    '24/7 Support',
    'Customer Reports',
  ],
  plan: PLANS.FULL_ACCESS,
  // plan: PLANS.ENTERPRISE,
  // cards: 'any',
  // trees: 'any',
  // designs: 'any',
  // admins: 'any',
  // personalBranding: true,
  // bulkUpload: true,
  // cardPermission: true,
  // onBoarding: true,
  // support: true,
  // customerServiceManager: true,
  // whiteLabel: true,
  // hrIntegration: true,
  // amount: 99912,
  // VAT: 13032,
}
// const Starter = {
//   heading: 'Professional',
//   services: [
//     '5 Customer cards​',
//     '1 Design Template',
//     '2-Push Notification Monthly​',
//     'Unlimited Redemption',
//     'Email Support',
//   ],
//   plan: PLANS.STARTER,
//   cards: 20,
//   trees: 1,
//   designs: 1,
//   admins: 1,
//   personalBranding: false,
//   bulkUpload: false,
//   cardPermission: false,
//   onBoarding: false,
//   support: false,
//   customerServiceManager: false,
//   whiteLabel: false,
//   hrIntegration: false,
//   // amount: 1440,
//   // VAT: 216,
//   // afterDiscount: 993.6,
// }
// const Basic = {
//   heading: 'Business',
//   previousPlan: 'Everything included in Starter, plus:',
//   services: [
//     'Up to 100 Card',
//     '3 Design Template',
//     '1 Admin (Control cardholder details)',
//     'Bulk Cardholder Upload',
//     'Customer Success Manager',
//     'Live Onbaording Training',
//     '24/7 Support',
//   ],
//   plan: PLANS.BASIC,
//   cards: 100,
//   trees: 20,
//   designs: 'any',
//   admins: 2,
//   personalBranding: true,
//   bulkUpload: true,
//   cardPermission: false,
//   onBoarding: false,
//   support: false,
//   customerServiceManager: false,
//   whiteLabel: false,
//   hrIntegration: false,
//   // amount: 4800,
//   // VAT: 720,
//   // afterDiscount: 3312,
// }
// const Business = {
//   heading: 'Business',
//   previousPlan: 'Everything included in Basic, plus:',
//   services: [
//     'Up to 100 Card',
//     '3 Design Template',
//     '1 Admin (Control cardholder details)',
//     'Bulk Cardholder Upload',
//     'Customer Success Manager',
//     'Live Onbaording Training',
//     '24/7 Support',
//   ],
//   plan: PLANS.BUSINESS,
//   cards: 500,
//   trees: 50,
//   designs: 'any',
//   admins: 5,
//   personalBranding: true,
//   bulkUpload: true,
//   cardPermission: true,
//   onBoarding: true,
//   support: true,
//   customerServiceManager: true,
//   whiteLabel: false,
//   hrIntegration: false,
//   // amount: 23400,
//   // VAT: 3510,
// }
// const Enterprise = {
//   heading: 'Enterprise',
//   previousPlan: 'Everything included in Business, plus:',
//   services: [
//     'Custom number of Cards',
//     'Unlimited Design Templates',
//     'Unlimited Admins (Control cardholder details)',
//     'Bulk Cardholder Upload',
//     'Cardholder Permissions',
//     'Live Onbaording Training',
//     'White-labelled Login & Interface',
//     'Dedicated Customer',
//     'Success Manager',
//     'Standard SSO & Directory Sync Integrations',
//     'Priority Technical Support',
//     '24/7 Support',
//   ],
//   plan: PLANS.ENTERPRISE,
//   cards: 'any',
//   trees: 'any',
//   designs: 'any',
//   admins: 'any',
//   personalBranding: true,
//   bulkUpload: true,
//   cardPermission: true,
//   onBoarding: true,
//   support: true,
//   customerServiceManager: true,
//   whiteLabel: true,
//   hrIntegration: true,
//   // amount: 99912,
//   // VAT: 13032,
// }

export const PLAN_DETAILS = (period) => {
  if (period === SUBSCRIPTION_PERIODS.MONTHLY) {
    const type = 'month'
    return {
      [PLANS.FREE]: {
        ...Free,
        type,
        subscriptionPeriod: period,
        amount: 0,
      },
      [PLANS.BASIC]: {
        ...Basic,
        type,
        subscriptionPeriod: period,
        amount: 10,
        price_id: 'price_1PiyzZBujuWpDwD6e4sKnP6a',
      },
      [PLANS.FULL_ACCESS]: {
        ...Full_Access,
        type,
        subscriptionPeriod: period,
        amount: 50,
        price_id: 'price_1PiwTgBujuWpDwD6dT3bO5gb',
      },
    }
  }

  // else {
  //   const type = 'year'
  //   return {
  //     [PLANS.STARTER]: {
  //       ...Starter,
  //       type,
  //       subscriptionPeriod: period,
  //       freeMonths: 2,
  //       amount: 1200,
  //     },
  //     [PLANS.BASIC]: {
  //       ...Basic,
  //       type,
  //       subscriptionPeriod: period,
  //       freeMonths: 2,
  //       amount: 3300,
  //     },
  //     [PLANS.BUSINESS]: {
  //       ...Business,
  //       type,
  //       subscriptionPeriod: period,
  //       freeMonths: 2,
  //       amount: 2700,
  //     },
  //     [PLANS.ENTERPRISE]: {
  //       ...Enterprise,
  //       type,
  //       subscriptionPeriod: period,
  //     },
  //   }
  // }
}
