import React from 'react'

function AddIcon({ size }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      width={size || '1em'}
      height={size || '1em'}
      fill='grey'
      class='inline'
    >
      <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'></path>
    </svg>
  )
}

export default AddIcon
