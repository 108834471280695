const CountryCodes = [
  {
    name: 'Afghanistan',
    flag: '🇦🇫',
    code: 'AF',
    dial_code: '+93',
    currencyCode: 'AFN',
    currencySymbol: 'Af',
    offset: 258,
  },
  {
    name: 'Åland Islands',
    flag: '🇦🇽',
    code: 'AX',
    dial_code: '+358',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 120,
  },
  {
    name: 'Albania',
    flag: '🇦🇱',
    code: 'AL',
    dial_code: '+355',
    currencyCode: 'ALL',
    currencySymbol: 'ALL',
    offset: 120,
  },
  {
    name: 'Algeria',
    flag: '🇩🇿',
    code: 'DZ',
    dial_code: '+213',
    currencyCode: 'DZD',
    currencySymbol: 'DA',
    offset: 120,
  },
  {
    name: 'American Samoa',
    flag: '🇦🇸',
    code: 'AS',
    dial_code: '+1684',
    currencyCode: 'USD',
    currencySymbol: '$',
    offset: -660,
  },
  {
    name: 'Andorra',
    flag: '🇦🇩',
    code: 'AD',
    dial_code: '+376',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Angola',
    flag: '🇦🇴',
    code: 'AO',
    dial_code: '+244',
    currencyCode: 'AOA',
    offset: 60,
  },
  {
    name: 'Anguilla',
    flag: '🇦🇮',
    code: 'AI',
    dial_code: '+1264',
    currencyCode: 'XCD',
    offset: -240,
  },
  {
    name: 'Antarctica',
    flag: '🇦🇶',
    code: 'AQ',
    dial_code: '+672',
    currencyCode: '',
  },
  {
    name: 'Antigua and Barbuda',
    flag: '🇦🇬',
    code: 'AG',
    dial_code: '+1268',
    currencyCode: 'XCD',
    offset: -240,
  },
  {
    name: 'Argentina',
    flag: '🇦🇷',
    code: 'AR',
    dial_code: '+54',
    currencyCode: 'ARS',
    currencySymbol: 'AR$',
    offset: -180,
  },
  {
    name: 'Armenia',
    flag: '🇦🇲',
    code: 'AM',
    dial_code: '+374',
    currencyCode: 'AMD',
    currencySymbol: 'AMD',
    offset: 240,
  },
  {
    name: 'Aruba',
    flag: '🇦🇼',
    code: 'AW',
    dial_code: '+297',
    currencyCode: 'AWG',
    offset: -240,
  },
  {
    name: 'Australia',
    flag: '🇦🇺',
    code: 'AU',
    dial_code: '+61',
    currencyCode: 'AUD',
    currencySymbol: 'AU$',
    offset: 600,
  },
  {
    name: 'Austria',
    flag: '🇦🇹',
    code: 'AT',
    dial_code: '+43',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Azerbaijan',
    flag: '🇦🇿',
    code: 'AZ',
    dial_code: '+994',
    currencyCode: 'AZN',
    currencySymbol: 'man.',
    offset: 240,
  },
  {
    name: 'Bahamas',
    flag: '🇧🇸',
    code: 'BS',
    dial_code: '+1242',
    currencyCode: 'BSD',
    offset: -300,
  },
  {
    name: 'Bahrain',
    flag: '🇧🇭',
    code: 'BH',
    dial_code: '+973',
    currencyCode: 'BHD',
    currencySymbol: 'BD',
    offset: 180,
  },
  {
    name: 'Bangladesh',
    flag: '🇧🇩',
    code: 'BD',
    dial_code: '+880',
    currencyCode: 'BDT',
    currencySymbol: 'Tk',
    offset: 360,
  },
  {
    name: 'Barbados',
    flag: '🇧🇧',
    code: 'BB',
    dial_code: '+1246',
    currencyCode: 'BBD',
    offset: -240,
  },
  {
    name: 'Belarus',
    flag: '🇧🇾',
    code: 'BY',
    dial_code: '+375',
    currencyCode: 'BYR',
    offset: 180,
  },
  {
    name: 'Belgium',
    flag: '🇧🇪',
    code: 'BE',
    dial_code: '+32',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Belize',
    flag: '🇧🇿',
    code: 'BZ',
    dial_code: '+501',
    currencyCode: 'BZD',
    currencySymbol: 'BZ$',
    offset: -360,
  },
  {
    name: 'Benin',
    flag: '🇧🇯',
    code: 'BJ',
    dial_code: '+229',
    currencyCode: 'XOF',
    currencySymbol: 'CFA',
    offset: 60,
  },
  {
    name: 'Bermuda',
    flag: '🇧🇲',
    code: 'BM',
    dial_code: '+1441',
    currencyCode: 'BMD',
    offset: -240,
  },
  {
    name: 'Bhutan',
    flag: '🇧🇹',
    code: 'BT',
    dial_code: '+975',
    currencyCode: 'BTN',
    offset: 360,
  },
  {
    name: 'Bolivia, Plurinational State of bolivia',
    flag: '🇧🇴',
    code: 'BO',
    dial_code: '+591',
    currencyCode: 'BOB',
    currencySymbol: 'Bs',
    offset: -240,
  },
  {
    name: 'Bosnia and Herzegovina',
    flag: '🇧🇦',
    code: 'BA',
    dial_code: '+387',
    currencyCode: 'BAM',
    currencySymbol: 'KM',
    offset: 60,
  },
  {
    name: 'Botswana',
    flag: '🇧🇼',
    code: 'BW',
    dial_code: '+267',
    currencyCode: 'BWP',
    currencySymbol: 'BWP',
    offset: 120,
  },
  {
    name: 'Bouvet Island',
    flag: '🇧🇻',
    code: 'BV',
    dial_code: '+47',
    currencyCode: 'NOK',
    currencySymbol: 'Nkr',
  },
  {
    name: 'Brazil',
    flag: '🇧🇷',
    code: 'BR',
    dial_code: '+55',
    currencyCode: 'BRL',
    currencySymbol: 'R$',
    offset: -300,
  },
  {
    name: 'British Indian Ocean Territory',
    flag: '🇮🇴',
    code: 'IO',
    dial_code: '+246',
    currencyCode: 'USD',
    currencySymbol: '$',
    offset: 360,
  },
  {
    name: 'Brunei Darussalam',
    flag: '🇧🇳',
    code: 'BN',
    dial_code: '+673',
    currencyCode: 'BND',
    currencySymbol: 'BN$',
    offset: 480,
  },
  {
    name: 'Bulgaria',
    flag: '🇧🇬',
    code: 'BG',
    dial_code: '+359',
    currencyCode: 'BGN',
    currencySymbol: 'BGN',
    offset: 120,
  },
  {
    name: 'Burkina Faso',
    flag: '🇧🇫',
    code: 'BF',
    dial_code: '+226',
    currencyCode: 'XOF',
    currencySymbol: 'CFA',
    offset: 0,
  },
  {
    name: 'Burundi',
    flag: '🇧🇮',
    code: 'BI',
    dial_code: '+257',
    currencyCode: 'BIF',
    currencySymbol: 'FBu',
    offset: 120,
  },
  {
    name: 'Cambodia',
    flag: '🇰🇭',
    code: 'KH',
    dial_code: '+855',
    currencyCode: 'KHR',
    currencySymbol: 'KHR',
    offset: 420,
  },
  {
    name: 'Cameroon',
    flag: '🇨🇲',
    code: 'CM',
    dial_code: '+237',
    currencyCode: 'XAF',
    currencySymbol: 'FCFA',
    offset: 60,
  },
  {
    name: 'Canada',
    flag: '🇨🇦',
    code: 'CA',
    dial_code: '+1',
    currencyCode: 'CAD',
    currencySymbol: 'CA$',
    offset: -360,
  },
  {
    name: 'Cape Verde',
    flag: '🇨🇻',
    code: 'CV',
    dial_code: '+238',
    currencyCode: 'CVE',
    currencySymbol: 'CV$',
    offset: -60,
  },
  {
    name: 'Cayman Islands',
    flag: '🇰🇾',
    code: 'KY',
    dial_code: '+345',
    currencyCode: 'KYD',
    offset: -300,
  },
  {
    name: 'Central African Republic',
    flag: '🇨🇫',
    code: 'CF',
    dial_code: '+236',
    currencyCode: 'XAF',
    currencySymbol: 'FCFA',
    offset: 60,
  },
  {
    name: 'Chad',
    flag: '🇹🇩',
    code: 'TD',
    dial_code: '+235',
    currencyCode: 'XAF',
    currencySymbol: 'FCFA',
    offset: 60,
  },
  {
    name: 'Chile',
    flag: '🇨🇱',
    code: 'CL',
    dial_code: '+56',
    currencyCode: 'CLP',
    currencySymbol: 'CL$',
    offset: -180,
  },
  {
    name: 'China',
    flag: '🇨🇳',
    code: 'CN',
    dial_code: '+86',
    currencyCode: 'CNY',
    currencySymbol: 'CN¥',
    offset: 480,
  },
  {
    name: 'Christmas Island',
    flag: '🇨🇽',
    code: 'CX',
    dial_code: '+61',
    currencyCode: 'AUD',
    currencySymbol: 'AU$',
    offset: 420,
  },
  {
    name: 'Cocos (Keeling) Islands',
    flag: '🇨🇨',
    code: 'CC',
    dial_code: '+61',
    currencyCode: 'AUD',
    currencySymbol: 'AU$',
    offset: 378,
  },
  {
    name: 'Colombia',
    flag: '🇨🇴',
    code: 'CO',
    dial_code: '+57',
    currencyCode: 'COP',
    currencySymbol: 'CO$',
    offset: -300,
  },
  {
    name: 'Comoros',
    flag: '🇰🇲',
    code: 'KM',
    dial_code: '+269',
    currencyCode: 'KMF',
    currencySymbol: 'CF',
    offset: 180,
  },
  {
    name: 'Congo',
    flag: '🇨🇬',
    code: 'CG',
    dial_code: '+242',
    currencyCode: 'XAF',
    currencySymbol: 'FCFA',
    offset: 60,
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    flag: '🇨🇩',
    code: 'CD',
    dial_code: '+243',
    currencyCode: 'CDF',
    currencySymbol: 'CDF',
  },
  {
    name: 'Cook Islands',
    flag: '🇨🇰',
    code: 'CK',
    dial_code: '+682',
    currencyCode: 'NZD',
    currencySymbol: 'NZ$',
    offset: -600,
  },
  {
    name: 'Costa Rica',
    flag: '🇨🇷',
    code: 'CR',
    dial_code: '+506',
    currencyCode: 'CRC',
    currencySymbol: '₡',
    offset: -360,
  },
  {
    name: "Cote d'Ivoire",
    flag: '🇨🇮',
    code: 'CI',
    dial_code: '+225',
    currencyCode: 'XOF',
    currencySymbol: 'CFA',
    offset: 0,
  },
  {
    name: 'Croatia',
    flag: '🇭🇷',
    code: 'HR',
    dial_code: '+385',
    currencyCode: 'HRK',
    currencySymbol: 'kn',
    offset: 60,
  },
  {
    name: 'Cuba',
    flag: '🇨🇺',
    code: 'CU',
    dial_code: '+53',
    currencyCode: 'CUP',
  },
  {
    name: 'Cyprus',
    flag: '🇨🇾',
    code: 'CY',
    dial_code: '+357',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 120,
  },
  {
    name: 'Czech Republic',
    flag: '🇨🇿',
    code: 'CZ',
    dial_code: '+420',
    currencyCode: 'CZK',
    currencySymbol: 'Kč',
    offset: 60,
  },
  {
    name: 'Denmark',
    flag: '🇩🇰',
    code: 'DK',
    dial_code: '+45',
    currencyCode: 'DKK',
    currencySymbol: 'Dkr',
    offset: 60,
  },
  {
    name: 'Djibouti',
    flag: '🇩🇯',
    code: 'DJ',
    dial_code: '+253',
    currencyCode: 'DJF',
    currencySymbol: 'Fdj',
    offset: 180,
  },
  {
    name: 'Dominica',
    flag: '🇩🇲',
    code: 'DM',
    dial_code: '+1767',
    currencyCode: 'XCD',
    offset: -240,
  },
  {
    name: 'Dominican Republic',
    flag: '🇩🇴',
    code: 'DO',
    dial_code: '+1849',
    currencyCode: 'DOP',
    currencySymbol: 'RD$',
    offset: -240,
  },
  {
    name: 'Ecuador',
    flag: '🇪🇨',
    code: 'EC',
    dial_code: '+593',
    currencyCode: 'USD',
    currencySymbol: '$',
    offset: -300,
  },
  {
    name: 'Egypt',
    flag: '🇪🇬',
    code: 'EG',
    dial_code: '+20',
    currencyCode: 'EGP',
    currencySymbol: 'EGP',
    offset: 120,
  },
  {
    name: 'El Salvador',
    flag: '🇸🇻',
    code: 'SV',
    dial_code: '+503',
    currencyCode: 'USD',
    currencySymbol: '$',
    offset: -360,
  },
  {
    name: 'Equatorial Guinea',
    flag: '🇬🇶',
    code: 'GQ',
    dial_code: '+240',
    currencyCode: 'XAF',
    currencySymbol: 'FCFA',
    offset: 60,
  },
  {
    name: 'Eritrea',
    flag: '🇪🇷',
    code: 'ER',
    dial_code: '+291',
    currencyCode: 'ERN',
    currencySymbol: 'Nfk',
    offset: 180,
  },
  {
    name: 'Estonia',
    flag: '🇪🇪',
    code: 'EE',
    dial_code: '+372',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 120,
  },
  {
    name: 'Ethiopia',
    flag: '🇪🇹',
    code: 'ET',
    dial_code: '+251',
    currencyCode: 'ETB',
    currencySymbol: 'Br',
    offset: 180,
  },
  {
    name: 'Falkland Islands (Malvinas)',
    flag: '🇫🇰',
    code: 'FK',
    dial_code: '+500',
    currencyCode: 'FKP',
    offset: -180,
  },
  {
    name: 'Faroe Islands',
    flag: '🇫🇴',
    code: 'FO',
    dial_code: '+298',
    currencyCode: 'DKK',
    currencySymbol: 'Dkr',
    offset: 0,
  },
  {
    name: 'Fiji',
    flag: '🇫🇯',
    code: 'FJ',
    dial_code: '+679',
    currencyCode: 'FJD',
    offset: 720,
  },
  {
    name: 'Finland',
    flag: '🇫🇮',
    code: 'FI',
    dial_code: '+358',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 120,
  },
  {
    name: 'France',
    flag: '🇫🇷',
    code: 'FR',
    dial_code: '+33',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'French Guiana',
    flag: '🇬🇫',
    code: 'GF',
    dial_code: '+594',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: -180,
  },
  {
    name: 'French Polynesia',
    flag: '🇵🇫',
    code: 'PF',
    dial_code: '+689',
    currencyCode: 'XPF',
    offset: -600,
  },
  {
    name: 'French Southern Territories',
    flag: '🇹🇫',
    code: 'TF',
    dial_code: '+262',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 300,
  },
  {
    name: 'Gabon',
    flag: '🇬🇦',
    code: 'GA',
    dial_code: '+241',
    currencyCode: 'XAF',
    currencySymbol: 'FCFA',
    offset: 60,
  },
  {
    name: 'Gambia',
    flag: '🇬🇲',
    code: 'GM',
    dial_code: '+220',
    currencyCode: 'GMD',
    offset: 0,
  },
  {
    name: 'Georgia',
    flag: '🇬🇪',
    code: 'GE',
    dial_code: '+995',
    currencyCode: 'GEL',
    currencySymbol: 'GEL',
    offset: 240,
  },
  {
    name: 'Germany',
    flag: '🇩🇪',
    code: 'DE',
    dial_code: '+49',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Ghana',
    flag: '🇬🇭',
    code: 'GH',
    dial_code: '+233',
    currencyCode: 'GHS',
    currencySymbol: 'GH₵',
    offset: 0,
  },
  {
    name: 'Gibraltar',
    flag: '🇬🇮',
    code: 'GI',
    dial_code: '+350',
    currencyCode: 'GIP',
    offset: 60,
  },
  {
    name: 'Greece',
    flag: '🇬🇷',
    code: 'GR',
    dial_code: '+30',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 120,
  },
  {
    name: 'Greenland',
    flag: '🇬🇱',
    code: 'GL',
    dial_code: '+299',
    currencyCode: 'DKK',
    currencySymbol: 'Dkr',
    offset: -180,
  },
  {
    name: 'Grenada',
    flag: '🇬🇩',
    code: 'GD',
    dial_code: '+1473',
    currencyCode: 'XCD',
    offset: -240,
  },
  {
    name: 'Guadeloupe',
    flag: '🇬🇵',
    code: 'GP',
    dial_code: '+590',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: -240,
  },
  {
    name: 'Guam',
    flag: '🇬🇺',
    code: 'GU',
    dial_code: '+1671',
    currencyCode: 'USD',
    currencySymbol: '$',
    offset: 600,
  },
  {
    name: 'Guatemala',
    flag: '🇬🇹',
    code: 'GT',
    dial_code: '+502',
    currencyCode: 'GTQ',
    currencySymbol: 'GTQ',
    offset: -360,
  },
  {
    name: 'Guernsey',
    flag: '🇬🇬',
    code: 'GG',
    dial_code: '+44',
    currencyCode: 'GBP',
    currencySymbol: '£',
    offset: 0,
  },
  {
    name: 'Guinea',
    flag: '🇬🇳',
    code: 'GN',
    dial_code: '+224',
    currencyCode: 'GNF',
    currencySymbol: 'FG',
    offset: 0,
  },
  {
    name: 'Guinea-Bissau',
    flag: '🇬🇼',
    code: 'GW',
    dial_code: '+245',
    currencyCode: 'XOF',
    currencySymbol: 'CFA',
    offset: 0,
  },
  {
    name: 'Guyana',
    flag: '🇬🇾',
    code: 'GY',
    dial_code: '+592',
    currencyCode: 'GYD',
    offset: -240,
  },
  {
    name: 'Haiti',
    flag: '🇭🇹',
    code: 'HT',
    dial_code: '+509',
    currencyCode: 'HTG',
    offset: -300,
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    flag: '🇭🇲',
    code: 'HM',
    dial_code: '+672',
    currencyCode: 'AUD',
    currencySymbol: 'AU$',
  },
  {
    name: 'Holy See (Vatican City State)',
    flag: '🇻🇦',
    code: 'VA',
    dial_code: '+379',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Honduras',
    flag: '🇭🇳',
    code: 'HN',
    dial_code: '+504',
    currencyCode: 'HNL',
    currencySymbol: 'HNL',
    offset: -360,
  },
  {
    name: 'Hong Kong',
    flag: '🇭🇰',
    code: 'HK',
    dial_code: '+852',
    currencyCode: 'HKD',
    currencySymbol: 'HK$',
    offset: 480,
  },
  {
    name: 'Hungary',
    flag: '🇭🇺',
    code: 'HU',
    dial_code: '+36',
    currencyCode: 'HUF',
    currencySymbol: 'Ft',
    offset: 60,
  },
  {
    name: 'Iceland',
    flag: '🇮🇸',
    code: 'IS',
    dial_code: '+354',
    currencyCode: 'ISK',
    currencySymbol: 'Ikr',
    offset: 0,
  },
  {
    name: 'India',
    flag: '🇮🇳',
    code: 'IN',
    dial_code: '+91',
    currencyCode: 'INR',
    currencySymbol: 'Rs',
    offset: 318,
  },
  {
    name: 'Indonesia',
    flag: '🇮🇩',
    code: 'ID',
    dial_code: '+62',
    currencyCode: 'IDR',
    currencySymbol: 'Rp',
    offset: 420,
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    flag: '🇮🇷',
    code: 'IR',
    dial_code: '+98',
    currencyCode: 'IRR',
    currencySymbol: 'IRR',
  },
  {
    name: 'Iraq',
    flag: '🇮🇶',
    code: 'IQ',
    dial_code: '+964',
    currencyCode: 'IQD',
    currencySymbol: 'IQD',
    offset: 180,
  },
  {
    name: 'Ireland',
    flag: '🇮🇪',
    code: 'IE',
    dial_code: '+353',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 0,
  },
  {
    name: 'Isle of Man',
    flag: '🇮🇲',
    code: 'IM',
    dial_code: '+44',
    currencyCode: 'GBP',
    currencySymbol: '£',
    offset: 0,
  },

  {
    name: 'Italy',
    flag: '🇮🇹',
    code: 'IT',
    dial_code: '+39',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Jamaica',
    flag: '🇯🇲',
    code: 'JM',
    dial_code: '+1876',
    currencyCode: 'JMD',
    currencySymbol: 'J$',
    offset: -300,
  },
  {
    name: 'Japan',
    flag: '🇯🇵',
    code: 'JP',
    dial_code: '+81',
    currencyCode: 'JPY',
    currencySymbol: '¥',
    offset: 540,
  },
  {
    name: 'Jersey',
    flag: '🇯🇪',
    code: 'JE',
    dial_code: '+44',
    currencyCode: 'GBP',
    currencySymbol: '£',
    offset: 0,
  },
  {
    name: 'Jordan',
    flag: '🇯🇴',
    code: 'JO',
    dial_code: '+962',
    currencyCode: 'JOD',
    currencySymbol: 'JD',
    offset: 120,
  },
  {
    name: 'Kazakhstan',
    flag: '🇰🇿',
    code: 'KZ',
    dial_code: '+7',
    currencyCode: 'KZT',
    currencySymbol: 'KZT',
    offset: 300,
  },
  {
    name: 'Kenya',
    flag: '🇰🇪',
    code: 'KE',
    dial_code: '+254',
    currencyCode: 'KES',
    currencySymbol: 'Ksh',
    offset: 180,
  },
  {
    name: 'Kiribati',
    flag: '🇰🇮',
    code: 'KI',
    dial_code: '+686',
    currencyCode: 'AUD',
    currencySymbol: 'AU$',
    offset: 720,
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    flag: '🇰🇵',
    code: 'KP',
    dial_code: '+850',
    currencyCode: 'KPW',
    offset: 540,
  },
  {
    name: 'Korea, Republic of South Korea',
    flag: '🇰🇷',
    code: 'KR',
    dial_code: '+82',
    currencyCode: 'KRW',
    currencySymbol: '₩',
  },
  {
    name: 'Kosovo',
    flag: '🇽🇰',
    code: 'XK',
    dial_code: '+383',
    currencyCode: 'EUR',
    currencySymbol: '€',
  },
  {
    name: 'Kuwait',
    flag: '🇰🇼',
    code: 'KW',
    dial_code: '+965',
    currencyCode: 'KWD',
    currencySymbol: 'KD',
    offset: 180,
  },
  {
    name: 'Kyrgyzstan',
    flag: '🇰🇬',
    code: 'KG',
    dial_code: '+996',
    currencyCode: 'KGS',
    offset: 360,
  },
  {
    name: 'Laos',
    flag: '🇱🇦',
    code: 'LA',
    dial_code: '+856',
    currencyCode: 'LAK',
    offset: 420,
  },
  {
    name: 'Latvia',
    flag: '🇱🇻',
    code: 'LV',
    dial_code: '+371',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 120,
  },
  {
    name: 'Lebanon',
    flag: '🇱🇧',
    code: 'LB',
    dial_code: '+961',
    currencyCode: 'LBP',
    currencySymbol: 'LB£',
    offset: 120,
  },
  {
    name: 'Lesotho',
    flag: '🇱🇸',
    code: 'LS',
    dial_code: '+266',
    currencyCode: 'LSL',
    offset: 120,
  },
  {
    name: 'Liberia',
    flag: '🇱🇷',
    code: 'LR',
    dial_code: '+231',
    currencyCode: 'LRD',
    offset: 0,
  },
  {
    name: 'Libyan Arab Jamahiriya',
    flag: '🇱🇾',
    code: 'LY',
    dial_code: '+218',
    currencyCode: 'LYD',
    currencySymbol: 'LD',
  },
  {
    name: 'Liechtenstein',
    flag: '🇱🇮',
    code: 'LI',
    dial_code: '+423',
    currencyCode: 'CHF',
    currencySymbol: 'CHF',
    offset: 60,
  },
  {
    name: 'Lithuania',
    flag: '🇱🇹',
    code: 'LT',
    dial_code: '+370',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 120,
  },
  {
    name: 'Luxembourg',
    flag: '🇱🇺',
    code: 'LU',
    dial_code: '+352',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Macao',
    flag: '🇲🇴',
    code: 'MO',
    dial_code: '+853',
    currencyCode: 'MOP',
    currencySymbol: 'MOP$',
  },
  {
    name: 'Macedonia',
    flag: '🇲🇰',
    code: 'MK',
    dial_code: '+389',
    currencyCode: 'MKD',
    currencySymbol: 'MKD',
    offset: 60,
  },
  {
    name: 'Madagascar',
    flag: '🇲🇬',
    code: 'MG',
    dial_code: '+261',
    currencyCode: 'MGA',
    currencySymbol: 'MGA',
    offset: 180,
  },
  {
    name: 'Malawi',
    flag: '🇲🇼',
    code: 'MW',
    dial_code: '+265',
    currencyCode: 'MWK',
    offset: 120,
  },
  {
    name: 'Malaysia',
    flag: '🇲🇾',
    code: 'MY',
    dial_code: '+60',
    currencyCode: 'MYR',
    currencySymbol: 'RM',
    offset: 480,
  },
  {
    name: 'Maldives',
    flag: '🇲🇻',
    code: 'MV',
    dial_code: '+960',
    currencyCode: 'MVR',
    offset: 300,
  },
  {
    name: 'Mali',
    flag: '🇲🇱',
    code: 'ML',
    dial_code: '+223',
    currencyCode: 'XOF',
    currencySymbol: 'CFA',
    offset: 0,
  },
  {
    name: 'Malta',
    flag: '🇲🇹',
    code: 'MT',
    dial_code: '+356',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Marshall Islands',
    flag: '🇲🇭',
    code: 'MH',
    dial_code: '+692',
    currencyCode: 'USD',
    currencySymbol: '$',
    offset: 720,
  },
  {
    name: 'Martinique',
    flag: '🇲🇶',
    code: 'MQ',
    dial_code: '+596',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: -240,
  },
  {
    name: 'Mauritania',
    flag: '🇲🇷',
    code: 'MR',
    dial_code: '+222',
    currencyCode: 'MRO',
    offset: 0,
  },
  {
    name: 'Mauritius',
    flag: '🇲🇺',
    code: 'MU',
    dial_code: '+230',
    currencyCode: 'MUR',
    currencySymbol: 'MURs',
    offset: 240,
  },
  {
    name: 'Mayotte',
    flag: '🇾🇹',
    code: 'YT',
    dial_code: '+262',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 180,
  },
  {
    name: 'Mexico',
    flag: '🇲🇽',
    code: 'MX',
    dial_code: '+52',
    currencyCode: 'MXN',
    currencySymbol: 'MX$',
    offset: -360,
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    flag: '🇫🇲',
    code: 'FM',
    dial_code: '+691',
    currencyCode: 'USD',
    currencySymbol: '$',
  },
  {
    name: 'Moldova',
    flag: '🇲🇩',
    code: 'MD',
    dial_code: '+373',
    currencyCode: 'MDL',
    currencySymbol: 'MDL',
    offset: 120,
  },
  {
    name: 'Monaco',
    flag: '🇲🇨',
    code: 'MC',
    dial_code: '+377',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Mongolia',
    flag: '🇲🇳',
    code: 'MN',
    dial_code: '+976',
    currencyCode: 'MNT',
    offset: 480,
  },
  {
    name: 'Montenegro',
    flag: '🇲🇪',
    code: 'ME',
    dial_code: '+382',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Montserrat',
    flag: '🇲🇸',
    code: 'MS',
    dial_code: '+1664',
    currencyCode: 'XCD',
    offset: -240,
  },
  {
    name: 'Morocco',
    flag: '🇲🇦',
    code: 'MA',
    dial_code: '+212',
    currencyCode: 'MAD',
    currencySymbol: 'MAD',
    offset: 0,
  },
  {
    name: 'Mozambique',
    flag: '🇲🇿',
    code: 'MZ',
    dial_code: '+258',
    currencyCode: 'MZN',
    currencySymbol: 'MTn',
    offset: 120,
  },
  {
    name: 'Myanmar',
    flag: '🇲🇲',
    code: 'MM',
    dial_code: '+95',
    currencyCode: 'MMK',
    currencySymbol: 'MMK',
  },
  {
    name: 'Namibia',
    flag: '🇳🇦',
    code: 'NA',
    dial_code: '+264',
    currencyCode: 'NAD',
    currencySymbol: 'N$',
    offset: 60,
  },
  {
    name: 'Nauru',
    flag: '🇳🇷',
    code: 'NR',
    dial_code: '+674',
    currencyCode: 'AUD',
    currencySymbol: 'AU$',
    offset: 720,
  },
  {
    name: 'Nepal',
    flag: '🇳🇵',
    code: 'NP',
    dial_code: '+977',
    currencyCode: 'NPR',
    currencySymbol: 'NPRs',
    offset: 327,
  },
  {
    name: 'Netherlands',
    flag: '🇳🇱',
    code: 'NL',
    dial_code: '+31',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Netherlands Antilles',
    flag: '',
    code: 'AN',
    dial_code: '+599',
    offset: -240,
  },
  {
    name: 'New Caledonia',
    flag: '🇳🇨',
    code: 'NC',
    dial_code: '+687',
    currencyCode: 'XPF',
    offset: 660,
  },
  {
    name: 'New Zealand',
    flag: '🇳🇿',
    code: 'NZ',
    dial_code: '+64',
    currencyCode: 'NZD',
    currencySymbol: 'NZ$',
    offset: 720,
  },
  {
    name: 'Nicaragua',
    flag: '🇳🇮',
    code: 'NI',
    dial_code: '+505',
    currencyCode: 'NIO',
    currencySymbol: 'C$',
    offset: -360,
  },
  {
    name: 'Niger',
    flag: '🇳🇪',
    code: 'NE',
    dial_code: '+227',
    currencyCode: 'XOF',
    currencySymbol: 'CFA',
    offset: 60,
  },
  {
    name: 'Nigeria',
    flag: '🇳🇬',
    code: 'NG',
    dial_code: '+234',
    currencyCode: 'NGN',
    currencySymbol: '₦',
    offset: 60,
  },
  {
    name: 'Niue',
    flag: '🇳🇺',
    code: 'NU',
    dial_code: '+683',
    currencyCode: 'NZD',
    currencySymbol: 'NZ$',
    offset: -660,
  },
  {
    name: 'Norfolk Island',
    flag: '🇳🇫',
    code: 'NF',
    dial_code: '+672',
    currencyCode: 'AUD',
    currencySymbol: 'AU$',
    offset: 678,
  },
  {
    name: 'Northern Mariana Islands',
    flag: '🇲🇵',
    code: 'MP',
    dial_code: '+1670',
    currencyCode: 'USD',
    currencySymbol: '$',
    offset: 600,
  },
  {
    name: 'Norway',
    flag: '🇳🇴',
    code: 'NO',
    dial_code: '+47',
    currencyCode: 'NOK',
    currencySymbol: 'Nkr',
    offset: 60,
  },
  {
    name: 'Oman',
    flag: '🇴🇲',
    code: 'OM',
    dial_code: '+968',
    currencyCode: 'OMR',
    currencySymbol: 'OMR',
    offset: 240,
  },
  {
    name: 'Pakistan',
    flag: '🇵🇰',
    code: 'PK',
    dial_code: '+92',
    currencyCode: 'PKR',
    currencySymbol: 'PKRs',
    offset: 300,
  },
  {
    name: 'Palau',
    flag: '🇵🇼',
    code: 'PW',
    dial_code: '+680',
    currencyCode: 'USD',
    currencySymbol: '$',
    offset: 540,
  },
  {
    name: 'Palestinian Territory, Occupied',
    flag: '🇵🇸',
    code: 'PS',
    dial_code: '+970',
    currencyCode: 'ILS',
    currencySymbol: '₪',
  },
  {
    name: 'Panama',
    flag: '🇵🇦',
    code: 'PA',
    dial_code: '+507',
    currencyCode: 'PAB',
    currencySymbol: 'B/.',
    offset: -300,
  },
  {
    name: 'Papua New Guinea',
    flag: '🇵🇬',
    code: 'PG',
    dial_code: '+675',
    currencyCode: 'PGK',
    offset: 600,
  },
  {
    name: 'Paraguay',
    flag: '🇵🇾',
    code: 'PY',
    dial_code: '+595',
    currencyCode: 'PYG',
    currencySymbol: '₲',
    offset: -240,
  },
  {
    name: 'Peru',
    flag: '🇵🇪',
    code: 'PE',
    dial_code: '+51',
    currencyCode: 'PEN',
    currencySymbol: 'S/.',
    offset: -300,
  },
  {
    name: 'Philippines',
    flag: '🇵🇭',
    code: 'PH',
    dial_code: '+63',
    currencyCode: 'PHP',
    currencySymbol: '₱',
    offset: 480,
  },
  {
    name: 'Pitcairn',
    flag: '🇵🇳',
    code: 'PN',
    dial_code: '+64',
    currencyCode: 'NZD',
    currencySymbol: 'NZ$',
    offset: -480,
  },
  {
    name: 'Poland',
    flag: '🇵🇱',
    code: 'PL',
    dial_code: '+48',
    currencyCode: 'PLN',
    currencySymbol: 'zł',
    offset: 60,
  },
  {
    name: 'Portugal',
    flag: '🇵🇹',
    code: 'PT',
    dial_code: '+351',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 0,
  },
  {
    name: 'Puerto Rico',
    flag: '🇵🇷',
    code: 'PR',
    dial_code: '+1939',
    currencyCode: 'USD',
    currencySymbol: '$',
    offset: -240,
  },
  {
    name: 'Qatar',
    flag: '🇶🇦',
    code: 'QA',
    dial_code: '+974',
    currencyCode: 'QAR',
    currencySymbol: 'QR',
    offset: 180,
  },
  {
    name: 'Romania',
    flag: '🇷🇴',
    code: 'RO',
    dial_code: '+40',
    currencyCode: 'RON',
    currencySymbol: 'RON',
    offset: 120,
  },
  {
    name: 'Russia',
    flag: '🇷🇺',
    code: 'RU',
    dial_code: '+7',
    currencyCode: 'RUB',
    currencySymbol: 'RUB',
    offset: 0,
  },
  {
    name: 'Rwanda',
    flag: '🇷🇼',
    code: 'RW',
    dial_code: '+250',
    currencyCode: 'RWF',
    currencySymbol: 'RWF',
    offset: 120,
  },
  {
    name: 'Reunion',
    flag: '🇷🇪',
    code: 'RE',
    dial_code: '+262',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 240,
  },
  {
    name: 'Saint Barthelemy',
    flag: '🇧🇱',
    code: 'BL',
    dial_code: '+590',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: -240,
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    flag: '🇸🇭',
    code: 'SH',
    dial_code: '+290',
    currencyCode: 'SHP',
    offset: 0,
  },
  {
    name: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
    code: 'KN',
    dial_code: '+1869',
    currencyCode: 'XCD',
    offset: -240,
  },
  {
    name: 'Saint Lucia',
    flag: '🇱🇨',
    code: 'LC',
    dial_code: '+1758',
    currencyCode: 'XCD',
    offset: -240,
  },
  {
    name: 'Saint Martin',
    flag: '🇲🇫',
    code: 'MF',
    dial_code: '+590',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: -240,
  },
  {
    name: 'Saint Pierre and Miquelon',
    flag: '🇵🇲',
    code: 'PM',
    dial_code: '+508',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: -180,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    flag: '🇻🇨',
    code: 'VC',
    dial_code: '+1784',
    currencyCode: 'XCD',
    offset: -240,
  },
  {
    name: 'Samoa',
    flag: '🇼🇸',
    code: 'WS',
    dial_code: '+685',
    currencyCode: 'WST',
    offset: 780,
  },
  {
    name: 'San Marino',
    flag: '🇸🇲',
    code: 'SM',
    dial_code: '+378',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Sao Tome and Principe',
    flag: '🇸🇹',
    code: 'ST',
    dial_code: '+239',
    currencyCode: 'STD',
    offset: 0,
  },
  {
    name: 'Saudi Arabia',
    flag: '🇸🇦',
    code: 'SA',
    dial_code: '+966',
    currencyCode: 'SAR',
    currencySymbol: 'SR',
    offset: 180,
  },
  {
    name: 'Senegal',
    flag: '🇸🇳',
    code: 'SN',
    dial_code: '+221',
    currencyCode: 'XOF',
    currencySymbol: 'CFA',
    offset: 0,
  },
  {
    name: 'Serbia',
    flag: '🇷🇸',
    code: 'RS',
    dial_code: '+381',
    currencyCode: 'RSD',
    currencySymbol: 'din.',
    offset: 60,
  },
  {
    name: 'Seychelles',
    flag: '🇸🇨',
    code: 'SC',
    dial_code: '+248',
    currencyCode: 'SCR',
    offset: 240,
  },
  {
    name: 'Sierra Leone',
    flag: '🇸🇱',
    code: 'SL',
    dial_code: '+232',
    currencyCode: 'SLL',
    offset: 0,
  },
  {
    name: 'Singapore',
    flag: '🇸🇬',
    code: 'SG',
    dial_code: '+65',
    currencyCode: 'SGD',
    currencySymbol: 'S$',
    offset: 480,
  },
  {
    name: 'Slovakia',
    flag: '🇸🇰',
    code: 'SK',
    dial_code: '+421',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Slovenia',
    flag: '🇸🇮',
    code: 'SI',
    dial_code: '+386',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Solomon Islands',
    flag: '🇸🇧',
    code: 'SB',
    dial_code: '+677',
    currencyCode: 'SBD',
    offset: 660,
  },
  {
    name: 'Somalia',
    flag: '🇸🇴',
    code: 'SO',
    dial_code: '+252',
    currencyCode: 'SOS',
    currencySymbol: 'Ssh',
    offset: 180,
  },
  {
    name: 'South Africa',
    flag: '🇿🇦',
    code: 'ZA',
    dial_code: '+27',
    currencyCode: 'ZAR',
    currencySymbol: 'R',
    offset: 120,
  },
  {
    name: 'South Sudan',
    flag: '🇸🇸',
    code: 'SS',
    dial_code: '+211',
    currencyCode: 'SSP',
    offset: 180,
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    flag: '🇬🇸',
    code: 'GS',
    dial_code: '+500',
    currencyCode: 'GBP',
    currencySymbol: '£',
  },
  {
    name: 'Spain',
    flag: '🇪🇸',
    code: 'SP',
    dial_code: '+34',
    currencyCode: 'EUR',
    currencySymbol: '€',
    offset: 60,
  },
  {
    name: 'Sri Lanka',
    flag: '🇱🇰',
    code: 'LK',
    dial_code: '+94',
    currencyCode: 'LKR',
    currencySymbol: 'SLRs',
    offset: 318,
  },
  {
    name: 'Sudan',
    flag: '🇸🇩',
    code: 'SD',
    dial_code: '+249',
    currencyCode: 'SDG',
    currencySymbol: 'SDG',
    offset: 180,
  },
  {
    name: 'Suri',
    flag: '🇸🇷',
    code: 'SR',
    dial_code: '+597',
    currencyCode: 'SRD',
    offset: -180,
  },
  {
    name: 'Svalbard and Jan Mayen',
    flag: '🇸🇯',
    code: 'SJ',
    dial_code: '+47',
    currencyCode: 'NOK',
    currencySymbol: 'Nkr',
    offset: 60,
  },
  {
    name: 'Swaziland',
    flag: '🇸🇿',
    code: 'SZ',
    dial_code: '+268',
    currencyCode: 'SZL',
    offset: 120,
  },
  {
    name: 'Sweden',
    flag: '🇸🇪',
    code: 'SE',
    dial_code: '+46',
    currencyCode: 'SEK',
    currencySymbol: 'Skr',
    offset: 60,
  },
  {
    name: 'Switzerland',
    flag: '🇨🇭',
    code: 'CH',
    dial_code: '+41',
    currencyCode: 'CHF',
    currencySymbol: 'CHF',
    offset: 60,
  },
  {
    name: 'Syrian Arab Republic',
    flag: '🇸🇾',
    code: 'SY',
    dial_code: '+963',
    currencyCode: 'SYP',
    currencySymbol: 'SY£',
    offset: 120,
  },
  {
    name: 'Taiwan',
    flag: '🇹🇼',
    code: 'TW',
    dial_code: '+886',
    currencyCode: 'TWD',
    currencySymbol: 'NT$',
    offset: 480,
  },
  {
    name: 'Tajikistan',
    flag: '🇹🇯',
    code: 'TJ',
    dial_code: '+992',
    currencyCode: 'TJS',
    offset: 300,
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    flag: '🇹🇿',
    code: 'TZ',
    dial_code: '+255',
    currencyCode: 'TZS',
    currencySymbol: 'TSh',
    offset: 180,
  },
  {
    name: 'Thailand',
    flag: '🇹🇭',
    code: 'TH',
    dial_code: '+66',
    currencyCode: 'THB',
    currencySymbol: '฿',
    offset: 420,
  },
  {
    name: 'Timor-Leste',
    flag: '🇹🇱',
    code: 'TL',
    dial_code: '+670',
    currencyCode: 'USD',
    currencySymbol: '$',
    offset: 540,
  },
  {
    name: 'Togo',
    flag: '🇹🇬',
    code: 'TG',
    dial_code: '+228',
    currencyCode: 'XOF',
    currencySymbol: 'CFA',
    offset: 0,
  },
  {
    name: 'Tokelau',
    flag: '🇹🇰',
    code: 'TK',
    dial_code: '+690',
    currencyCode: 'NZD',
    currencySymbol: 'NZ$',
    offset: 780,
  },
  {
    name: 'Tonga',
    flag: '🇹🇴',
    code: 'TO',
    dial_code: '+676',
    currencyCode: 'TOP',
    currencySymbol: 'T$',
    offset: 780,
  },
  {
    name: 'Trinidad and Tobago',
    flag: '🇹🇹',
    code: 'TT',
    dial_code: '+1868',
    currencyCode: 'TTD',
    currencySymbol: 'TT$',
  },
  {
    name: 'Tunisia',
    flag: '🇹🇳',
    code: 'TN',
    dial_code: '+216',
    currencyCode: 'TND',
    currencySymbol: 'DT',
    offset: 60,
  },
  {
    name: 'Turkey',
    flag: '🇹🇷',
    code: 'TR',
    dial_code: '+90',
    currencyCode: 'TRY',
    currencySymbol: 'TL',
    offset: 120,
  },
  {
    name: 'Turkmenistan',
    flag: '🇹🇲',
    code: 'TM',
    dial_code: '+993',
    currencyCode: 'TMT',
    offset: 300,
  },
  {
    name: 'Turks and Caicos Islands',
    flag: '🇹🇨',
    code: 'TC',
    dial_code: '+1649',
    currencyCode: 'USD',
    currencySymbol: '$',
    offset: -300,
  },
  {
    name: 'Tuvalu',
    flag: '🇹🇻',
    code: 'TV',
    dial_code: '+688',
    currencyCode: 'AUD',
    currencySymbol: 'AU$',
    offset: 720,
  },
  {
    name: 'Uganda',
    flag: '🇺🇬',
    code: 'UG',
    dial_code: '+256',
    currencyCode: 'UGX',
    currencySymbol: 'USh',
    offset: 180,
  },
  {
    name: 'Ukraine',
    flag: '🇺🇦',
    code: 'UA',
    dial_code: '+380',
    currencyCode: 'UAH',
    currencySymbol: '₴',
    offset: 120,
  },
  {
    name: 'United Arab Emirates',
    flag: '🇦🇪',
    code: 'AE',
    dial_code: '+971',
    currencyCode: 'AED',
    currencySymbol: 'AED',
    offset: 240,
  },
  {
    name: 'United Kingdom',
    flag: '🇬🇧',
    code: 'GB',
    dial_code: '+44',
    currencyCode: 'GBP',
    currencySymbol: '£',
    offset: 0,
  },
  {
    name: 'United States',
    flag: '🇺🇸',
    code: 'US',
    dial_code: '+1',
    currencyCode: 'USD',
    currencySymbol: '$',
  },
  {
    name: 'Uruguay',
    flag: '🇺🇾',
    code: 'UY',
    dial_code: '+598',
    currencyCode: 'UYU',
    currencySymbol: '$U',
    offset: -180,
  },
  {
    name: 'Uzbekistan',
    flag: '🇺🇿',
    code: 'UZ',
    dial_code: '+998',
    currencyCode: 'UZS',
    currencySymbol: 'UZS',
    offset: 300,
  },
  {
    name: 'Vanuatu',
    flag: '🇻🇺',
    code: 'VU',
    dial_code: '+678',
    currencyCode: 'VUV',
    offset: 660,
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    flag: '🇻🇪',
    code: 'VE',
    dial_code: '+58',
    currencyCode: 'VEF',
    currencySymbol: 'Bs.F.',
    offset: -240,
  },
  {
    name: 'Vietnam',
    flag: '🇻🇳',
    code: 'VN',
    dial_code: '+84',
    currencyCode: 'VND',
    currencySymbol: '₫',
    offset: 420,
  },
  {
    name: 'Virgin Islands, British',
    flag: '🇻🇬',
    code: 'VG',
    dial_code: '+1284',
    currencyCode: 'USD',
    currencySymbol: '$',
    offset: -240,
  },
  {
    name: 'Virgin Islands, U.S.',
    flag: '🇻🇮',
    code: 'VI',
    dial_code: '+1340',
    currencyCode: 'USD',
    currencySymbol: '$',
    offset: -240,
  },
  {
    name: 'Wallis and Futuna',
    flag: '🇼🇫',
    code: 'WF',
    dial_code: '+681',
    currencyCode: 'XPF',
    offset: 720,
  },
  {
    name: 'Yemen',
    flag: '🇾🇪',
    code: 'YE',
    dial_code: '+967',
    currencyCode: 'YER',
    currencySymbol: 'YR',
    offset: 180,
  },
  {
    name: 'Zambia',
    flag: '🇿🇲',
    code: 'ZM',
    dial_code: '+260',
    currencyCode: 'ZMW',
    offset: 60,
  },
  {
    name: 'Zimbabwe',
    flag: '🇿🇼',
    code: 'ZW',
    dial_code: '+263',
    currencyCode: 'ZWL',
    currencySymbol: 'ZWL$',
    offset: 120,
  },
]

export default CountryCodes
