import { Menu } from '@headlessui/react'
import { ErrorMessage, useFormikContext } from 'formik'
import AddFilledIcon from 'icons/add-filled'
import ArrowDropdownIcon from 'icons/arrow-dropdown'
import RemoveIcon from 'icons/remove'
import countryCodes from 'enums/countrycode'

const TYPES = ['work', 'home', 'custom']

function CountryCodes({ name, idx }) {
  const formik = useFormikContext()
  const selectedCountry = countryCodes.find(
    (countryCode) => countryCode.dial_code === formik.values[name][idx].dialCode
  ) || { flag: '', dial_code: '', name: '' } // Provide the default country name

  const onSelect = (country) => {
    formik.setFieldValue(`${name}[${idx}]`, {
      ...formik.values[name][idx],
      dialCode: country.dial_code,
    })
  }

  console.log(formik.values[name])

  const renderMenu = () => (
    <Menu as={'div'} className='tw-relative tw-text-left tw-w-full'>
      <Menu.Button className='tw-inline-flex tw-w-full tw-absolute tw-top-[-15px] tw-h-[30px] tw-rounded-md tw-px-[13px] tw-py-[7px]  tw-text-xs tw-font-medium tw-text-gray-700 tw-focus:outline-none'>
        {`${selectedCountry.flag} ${selectedCountry.dial_code}`}
      </Menu.Button>
      <Menu.Items className='tw-origin-top-right tw-max-h-56 tw-overflow-y-auto tw-py-[5px] tw-absolute tw-left-0 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:outline-none tw-z-20 tw-transform tw-opacity-100 tw-scale-100'>
        {countryCodes.map((country) => (
          <Menu.Item
            key={country.code}
            as={'div'}
            className='tw-block tw-text-regular tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 tw-focus:outline-none focus:tw-bg-gray-100 tw-transition tw-ease-in-out tw-duration-150'
          >
            {({ active }) => (
              <div
                onClick={() => onSelect(country)}
                className={`${
                  active && 'tw-bg-electricGreen tw-text-white'
                } tw-px-[10px]`}
              >
                {`${country.flag} ${country.dial_code} (${country.name})`}
              </div>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  )

  return (
    <div>
      <div className='tw-cursor-pointer tw-flex tw-flex-none tw-items-center tw-w-24 tw-relative tw-text-center tw-text-slate-400 tw-text-sm'>
        {renderMenu()}
        <div className='tw-absolute tw-border-r tw-border-slate-300  tw-text-blue tw-pointer-events-none tw-px-2 tw-py-1 tw-right-0'>
          <ArrowDropdownIcon />
        </div>
      </div>
    </div>
  )
}

function FieldType({ item, idx, name }) {
  const formik = useFormikContext()

  return (
    <>
      <Menu as={'div'} className='tw-relative tw-text-left tw-w-full'>
        <Menu.Button className='tw-inline-flex tw-w-full tw-absolute tw-top-[-15px] tw-h-[30px] tw-rounded-md tw-px-[0px] tw-py-[5px] tw-bg-white tw-text-regular tw-font-medium tw-text-gray-700 tw-focus:outline-none'>
          {item.type}
        </Menu.Button>
        <Menu.Items className='tw-origin-top-right  tw-py-[5px] tw-absolute tw-left-0 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:outline-none tw-z-20 tw-transform tw-opacity-100 tw-scale-100'>
          {TYPES.map((type) => (
            <Menu.Item
              key={type}
              as={'div'}
              className='tw-block tw-text-regular tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 tw-focus:outline-none focus:tw-bg-gray-100 tw-transition tw-ease-in-out tw-duration-150'
            >
              {({ active }) => (
                <div
                  onClick={() =>
                    formik.setFieldValue(`${name}[${idx}].type`, type)
                  }
                  className={`${
                    active && 'tw-bg-electricGreen tw-text-white'
                  } tw-px-[10px]`}
                >
                  {type}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
      <div class='tw-absolute tw-border-r tw-border-slate-300 tw-ml-2 tw-text-blue tw-pointer-events-none tw-px-2 tw-py-1 tw-right-0'>
        <ArrowDropdownIcon />
      </div>
    </>
  )
}

function ListInputFieldPhone({
  label,
  required,
  btnLabel,
  name,
  type,
  className,
}) {
  const formik = useFormikContext()

  const addField = () => {
    const defaultValues = {
      type: 'work',
      dialCode: '+966',
    }
    formik.setFieldValue(name, [...formik.values[name], { ...defaultValues }])
  }

  const removeField = (idx) => {
    formik.values[name].splice(idx, 1)
    formik.setValues({ ...formik.values })
  }

  return (
    <div className={className}>
      <label class='tw-mb-2 tw-text-regular tw-text-navyBlue tw-flex tw-justify-between'>
        <div>
          {label}
          {required && <span class='tw-ml-2 tw-text-red-600'>*</span>}
        </div>
      </label>
      {formik.values[name].map((item, idx) => (
        <>
          <div class='tw-flex tw-items-center tw-border tw-border-slate-300 tw-my-2 tw-rounded-md'>
            <div class='tw-cursor-pointer tw-flex tw-flex-none tw-items-center tw-w-36 tw-relative tw-text-center tw-text-slate-400 tw-text-sm'>
              <div
                onClick={() => removeField(idx)}
                class='tw-ml-4 tw-mr-2 tw-my-[6px]'
              >
                <RemoveIcon />
              </div>
              <FieldType item={item} idx={idx} name={name} />
            </div>
            <CountryCodes name={name} idx={idx} />

            <input
              autocapitalize='off'
              class='tw-text-xs tw-leading-regular tw-appearance-none tw-block tw-border-none tw-rounded-md tw-placeholder-gray-300 tw-flex-1 tw-px-[10px] tw-py-[5px] tw-w-full tw-z-10$'
              type={type || 'text'}
              {...formik.getFieldProps(`${name}[${idx}].value`)}
            />
            <div class='tw-text-xs tw-font-light tw-text-red-600 tw-mt-2'></div>
          </div>
          <ErrorMessage
            component='div'
            className='error'
            name={`${name}[${idx}].value`}
          />
        </>
      ))}
      <button
        class='tw-border tw-border-slate-300 tw-flex tw-hover:bg-slate-50 tw-items-center tw-justify-center tw-mt-3 tw-p-2 tw-rounded-md tw-text-slate-400 tw-text-regular tw-transition-colors tw-w-full'
        type='button'
        onClick={addField}
      >
        <AddFilledIcon />
        <span class='tw-ml-1.5'>Add {btnLabel}</span>
      </button>
    </div>
  )
}

export default ListInputFieldPhone
