import React from 'react'

function TextColumn({ text }) {
  return (
    <div class='tw-w-2/12 tw-items-center tw-hidden md:tw-flex tw-overflow-hidden'>
      <div class='tw-text-[12px] tw-text-[#959595] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap'>
        {text}
      </div>
    </div>
  )
}

export default TextColumn
