import { useAuth } from 'context/auth'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from 'utils/api'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}
function Success() {
  const navigate = useNavigate()
  const query = useQuery()
  const session_Id = query.get('session_id')
  const plan = query.get('plan')
  const { currentUser } = useAuth()
  console.log(currentUser.account._id, 'current')
  console.log('Session ID:', session_Id)

  const RetrieveSession = async () => {
    const { response } = await Api('/stripe/retrive', 'post', {
      payload: { session_Id, account_id: currentUser.account._id, plan },
    })
    if (response) navigate('./home')
  }

  const onLoad = () => {
    RetrieveSession()
  }
  useEffect(onLoad, [])

  return (
    <div>
      <div>Payment Success Please Wait ...</div>;
    </div>
  )
}

export default Success
