import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import ComponentLoader from "./component-loader";
import AdminLayout from "layouts/admin/components/admin-layout";
import Team from "layouts/admin/team/team";
import Teams from "layouts/admin/team";
import Success from "layouts/admin/success";
// import Rebranding from 'layouts/admin/rebranding/rebranding'

// MAIN
const Admin = ComponentLoader(lazy(() => import("../layouts/admin/admin")));

// ACCOUNT
const Account = ComponentLoader(
  lazy(() => import("../layouts/common/account/account"))
);
const Profile = ComponentLoader(
  lazy(() => import("../layouts/common/account/profile"))
);
const UpdatePassword = ComponentLoader(
  lazy(() => import("../layouts/common/account/update-pass"))
);

// CARD-HOLDER
const CardHolders = ComponentLoader(
  lazy(() => import("../layouts/admin/cardholder"))
);
const CardHoldersList = ComponentLoader(
  lazy(() => import("../layouts/admin/cardholder/cardholder"))
);
const Bulkmanage = ComponentLoader(
  lazy(() => import("../layouts/admin/cardholder/bulkmanage"))
);
const ViewInsight = ComponentLoader(
  lazy(() => import("../layouts/admin/components/view-insight"))
);

// MANAGE DESIGN
const Designs = ComponentLoader(lazy(() => import("../layouts/admin/design")));
const CreateDesign = ComponentLoader(
  lazy(() => import("../layouts/admin/design/design-form"))
);
const DesignList = ComponentLoader(
  lazy(() => import("../layouts/admin/design/design-table"))
);

// BROADCAST
const BroadCastModule = ComponentLoader(
  lazy(() => import("../layouts/admin/broadcast"))
);
const BroadCast = ComponentLoader(
  lazy(() => import("../layouts/admin/broadcast/broadcast"))
);

// SETTINGS
const OrganisationSettings = ComponentLoader(
  lazy(() => import("../layouts/admin/settings/settings"))
);

// SUBSCRIPTION PLANS
const SubscriptionPlans = ComponentLoader(
  lazy(() => import("../layouts/admin/subscriptionplan"))
);
const Payment = ComponentLoader(
  lazy(() => import("../layouts/admin/payment/payment"))
);
const Payfast = ComponentLoader(
  lazy(() => import("../layouts/admin/payfast/payfast"))
);

const Rebranding = ComponentLoader(
  lazy(() => import("../layouts/admin/rebranding/rebranding"))
);
const PayByCard = ComponentLoader(
  lazy(() =>
    import(
      "../layouts/authentication/register/organisation/subscription/paybycard"
    )
  )
);

const ContactSales = ComponentLoader(
  lazy(() =>
    import(
      "../layouts/authentication/register/organisation/subscription/submiterequest"
    )
  )
);

const PaymentSuccess = ComponentLoader(
  lazy(() =>
    import(
      "../layouts/authentication/register/organisation/subscription/payment-success"
    )
  )
);

const PaymentFailed = ComponentLoader(
  lazy(() =>
    import(
      "../layouts/authentication/register/organisation/subscription/payment-failed"
    )
  )
);

const OrganisationRoutes = [
  {
    path: "",
    element: <AdminLayout />,
    children: [
      {
        path: "",
        index: true,
        element: <Admin />,
      },
      {
        path: "account",
        element: <Account />,
        children: [
          {
            path: "",
            index: true,
            element: <Profile />,
          },
          {
            path: "password",
            element: <UpdatePassword />,
          },
        ],
      },
      {
        path: "customers",
        element: <CardHolders />,
        children: [
          {
            path: "",
            index: true,
            element: <CardHoldersList />,
          },
          {
            path: "bulkmanage",
            element: <Bulkmanage />,
          },
          {
            path: "view-insight",
            element: <ViewInsight />,
          },
        ],
      },
      {
        path: "team",
        element: <Teams />,
        children: [
          {
            path: "",
            index: true,
            element: <Team />,
          },
          // {
          //   path: 'bulkmanage',
          //   element: <Bulkmanage />,
          // },
        ],
      },
      {
        path: "broadcast",
        element: <BroadCastModule />,
        children: [
          {
            path: "",
            index: true,
            element: <BroadCast />,
          },
        ],
      },
      {
        path: "design",
        element: <Designs />,
        children: [
          {
            path: "",
            index: true,
            element: <DesignList />,
          },
          {
            path: "create",
            element: <CreateDesign />,
          },
          {
            path: "update",
            element: <CreateDesign />,
          },
        ],
      },
      {
        path: "settings",
        element: <OrganisationSettings />,
      },
      {
        path: "rebranding",
        element: <Rebranding />,
      },

      {
        path: "*",
        element: <Navigate to={""} />,
      },
    ],
  },
  {
    path: "subscribe",
    children: [
      {
        path: "",
        element: <SubscriptionPlans />,
      },
      {
        path: "payment",
        element: <Payment />,
      },
      {
        path: "pay-by-card",
        element: <PayByCard />,
      },
      {
        path: "contact",
        element: <ContactSales />,
      },
      {
        path: "success",
        element: <Success />,
      },
      {
        path: "failed",
        element: <div>Failed</div>,
      },
    ],
  },
  {
    path: "payfast",
    element: <Payfast />,
  },
  {
    path: "payment-success",
    element: <PaymentSuccess />,
  },
  {
    path: "payment-failed",
    element: <PaymentFailed />,
  },
  {
    path: "*",
    element: <Navigate to={""} />,
  },
];

export default OrganisationRoutes;
