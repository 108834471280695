import { useCardHolders } from 'layouts/admin/cardholder/card-holders.context'
import DownloadIcon from 'icons/download'
import React from 'react'

function DownloadList() {
  const { downloadExcel } = useCardHolders()
  return (
    <div
      onClick={downloadExcel}
      class='hover:tw-cursor-pointer tw-text-navyBlue hover:tw-text-electricGreen tw-hidden lg:tw-flex tw-gap-[5px] tw-px-[18px] tw-py-[10px] tw-items-center tw-relative tw-z-[1]'
    >
      <DownloadIcon />
      <span class='tw-text-regular tw-flex tw-items-center '>
        Download list (.xlsx)
      </span>
    </div>
  )
}

export default DownloadList
