import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react'
import * as authHelper from 'utils/auth-helper'
import Api from 'utils/api'
import Loader from 'components/loader'

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  onBoardingStep: 1,
  setOnBoardingStep: () => {},
  logout: () => {},
  theme: {},
  setTheme: () => {},
}

const defaultTheme = {
  buttonBackgroundColor : "#2C3255",
  secondarybuttonBackgroundColor : "#F35658",
  buttonTextColor: 'black',
  pageTextColor: 'black',
  pageBackgroundColor: 'white'
}

const AuthContext = createContext(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState()
  const [onBoardingStep, setOnBoardingStep] = useState(1)
  const [theme, setTheme] = useState(defaultTheme)

  const favicon = currentUser?.account?.branding?.faviconImage

  const updateFavicon = (faviconURL) => {
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = faviconURL;
      document.getElementsByTagName('head')[0].appendChild(newLink);
    } else {
      link.href = faviconURL;
    }
  };

  useEffect(() => {
    if (currentUser) {
      updateFavicon(favicon);
    }
  }, [favicon, currentUser]);

  const saveAuth = (auth) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  useEffect(() => {
    if (currentUser?.onBoardingStep)
      setOnBoardingStep(currentUser.onBoardingStep)
  }, [currentUser?.onBoardingStep])

  useEffect(() => {
    if (currentUser?.account?.branding)
      setTheme(currentUser?.account?.branding)
}, [currentUser?.account?.branding])

console.log(theme, 'faraz')
console.log(currentUser, 'farazuser')

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        onBoardingStep,
        setOnBoardingStep,
        logout,
      }}
    >
      <div style={{ backgroundColor: currentUser?.account?.branding?.pageBackgroundColor, color: currentUser?.account?.branding?.pageTextColor}}>
      {children}
      </div>
    </AuthContext.Provider>
  )
}

const AuthInit = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (didRequest.current != auth) {
          setShowSplashScreen(true)
          const { response } = await Api('/auth/verify_token', 'get')
          if (response) {
            setCurrentUser(response.data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      didRequest.current = auth
    }

    if (auth && auth.token) {
      requestUser(auth.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [auth])

  console.log('auth loader', showSplashScreen)

  return showSplashScreen ? (
    <div className='tw-w-full tw-h-[100vh] tw-flex tw-flex-col tw-items-center tw-justify-center'>
      <Loader size={8} />
    </div>
  ) : (
    <>{children}</>
  )
}

export { AuthProvider, AuthInit, useAuth }
