import React from 'react'

function CloseIcon({ onClick, className }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      width='1em'
      height='1em'
      fill='grey'
      stroke='grey'
      strokeWidth='1'
      className={`w-[13px] h-[13px] tw-p-1 ${className}`}
      onClick={onClick}
    >
      <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z'></path>
    </svg>
  )
}

export default CloseIcon
