import PassCover from 'icons/pass-cover'
import PassLogo from 'icons/pass-logo'
import React from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import { invertTextColor } from 'utils'

function ApplePass({ design, pass }) {
  const { backgroundColor, logoImage, stripImage, fullName, points, passCode } =
    design

  const isEnglish = (text) => {
    if (typeof text !== 'string') return true
    const cleanText = text.replace(/\s+/g, '').toLowerCase()
    return /[a-zA-Z]/g.test(cleanText)
  }
  console.log('hello', pass)

  return (
    <div className='tw-relative' style={{ maxWidth: '330px' }}>
      <div
        className='tw-visible tw-w-[330px] tw-shadow-lg tw-text-white tw-rounded-2xl'
        style={{ backgroundColor }}
      >
        <div className='tw-flex tw-flex-col tw-h-full tw-justify-between tw-pb-6'>
          <div className='tw-mt-3 tw-ml-3 tw-w-1/6'>
            {logoImage ? (
              <img class='even:tw-hidden' src={logoImage} alt='Logo'></img>
            ) : (
              <div id='default-logo' className='tw-block tw-mt-3 tw-ml-3 '>
                <PassLogo color={invertTextColor(backgroundColor)} />
              </div>
            )}
          </div>
          <div className='tw-items-center tw-justify-center tw-overflow-hidden tw-flex tw-aspect-[1033/407] tw-my-[15px]'>
            {stripImage ? (
              <img
                class='even:tw-hidden tw-w-full'
                src={stripImage}
                alt='Primary strip'
              ></img>
            ) : (
              <span
                id='default-strip'
                className='tw-flex tw-items-center tw-px-6 tw-w-full  tw-aspect-[3/1]'
              >
                <div className='tw-mx-auto tw-w-2/3'>
                  <PassCover color={invertTextColor(backgroundColor)} />
                </div>
              </span>
            )}
          </div>
          <div className='tw-mb-12'>
            <div className='tw-px-6'>
              {/* <p
                className='tw-text-[12px] tw-uppercase'
                style={{
                  color: invertTextColor(backgroundColor),
                  textAlign: isEnglish(jobTitle) ? 'left' : 'right',
                }}
              >
                {jobTitle}&nbsp;
              </p> */}
              {/* <div className='tw-flex tw-justify-between'>
                <p className='tw-text-1xl'>Customer Name:</p>
                <p>Points:</p>
              </div> */}
              <p
                className='tw-flex tw-justify-between tw-text-1xl tw-font-extralight tw-mt-[-20px]'
                style={{
                  color: invertTextColor(backgroundColor),
                  textAlign: isEnglish(fullName) ? 'left' : 'right',
                }}
              >
                {'Customer Name'}

                <div className='tw-text-1xl'> Points </div>
              </p>
              <p
                className='tw-flex tw-justify-between tw-text-1xl tw-font-extralight'
                style={{
                  color: invertTextColor(backgroundColor),
                  textAlign: isEnglish(fullName) ? 'left' : 'right',
                }}
              >
                {fullName}

                <div className='tw-text-1xl'> {points || 0} </div>
              </p>
            </div>
          </div>
          <div className='tw-bg-white tw-mx-auto tw-rounded-md tw-card-qrcode tw-max-w-[138px] tw-p-[5px]'>
            <QRCodeCanvas
              value={passCode}
              title={passCode}
              viewBox='0 0 53 53'
              size={128}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplePass
