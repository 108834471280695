import Searchicon from 'icons/searchicon'
import React from 'react'

function SearchList({ search }) {
  return (
    <div class='tw-ml-[10px] tw-w-full md:tw-w-[250px] tw-flex tw-py-[5px] tw-rounded-[8px] tw-bg-white tw-px-[10px] tw-gap-[10px] tw-items-center tw-shrink-0'>
      <Searchicon />
      <input
        class='tw-w-full tw-border-none placeholder:tw-text-slate-300 tw-font-[14px] focus:tw-ring-0 tw-p-0'
        placeholder='Start typing to search'
        type='text'
        onChange={search}
      />
    </div>
  )
}

export default SearchList
