import Loader from 'components/loader'
import { useAuth } from 'context/auth'
import React, { useState } from 'react'
import Api from 'utils/api'

const Delete = ({ user, userDeleted, idx }) => {
  const { cardholders, setCardholders } = useAuth()
  const [processing, setProcessing] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleDelete = async () => {
    setProcessing(true)
    const { response } = await Api('/user?user_id=' + user._id, 'delete')
    setProcessing(false)
    if (response) {
      setCardholders(cardholders - 1)
      userDeleted(idx)
    }
    closeModal()
  }

  return (
    <div className='tw-p-4'>
      <button
        onClick={openModal}
        className='tw-flex tw-justify-content-between tw-text-medium tw-text-navyBlue tw-invisible group-hover:tw-visible hover:tw-cursor-pointer tw-undefined'
      >
        Delete
      </button>
      {isModalOpen && (
        <div className='tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-z-50'>
          <div className='tw-modal-container tw-bg-white tw-w-1/3 tw-rounded-lg tw-shadow-lg tw-p-4'>
            <h2 className='tw-text-xl tw-mb-4'>
              Are you sure you want to delete?
            </h2>

            <div className='tw-mt-4 tw-flex tw-justify-end'>
              <button
                onClick={handleDelete}
                className='tw-px-4 tw-py-2 tw-bg-red-500 tw-text-white tw-rounded-md tw-mr-2'
              >
                {processing ? <Loader /> : 'Delete'}
              </button>
              <button
                onClick={closeModal}
                className='tw-px-4 tw-py-2 tw-bg-gray-300 tw-rounded-md'
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Delete
