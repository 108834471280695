import React, { useState } from 'react'
import { Combobox, Menu } from '@headlessui/react'
import CDInputOutline from 'components/cd-input-outline'
import { ErrorMessage, Form, Formik, useFormikContext } from 'formik'
import AddFilledIcon from 'icons/add-filled'
import AddIcon from 'icons/add'
import ArrowDropdownIcon from 'icons/arrow-dropdown'
import RemoveIcon from 'icons/remove'
import * as yup from 'yup'
import Loader from 'components/loader'
import Api from 'utils/api'
import { useCardHolders } from 'layouts/admin/cardholder/card-holders.context'
import { useAuth } from 'context/auth'
import { isEmptyJSON, showToastMessage } from 'utils'
import ListInputField from 'components/list-input-field'
import Prefix from 'components/prefix'
import { ROLE } from 'enums/user'
import ListInputFieldPhone from 'components/list-input-field-phone'
import CountryCodes from 'enums/countrycode'

const TYPES = ['work', 'home', 'custom']

const CreateCardHolder = yup.object({
  prefix: yup.string().label('Prefix'),
  firstName: yup.string().required().label('First name'),
  lastName: yup.string().required().label('Last name'),
  jobTitle: yup.string().required().label('Job title'),
  organisationName: yup.string().required().label('Company / Organisation'),
  // license: yup.string(),
  // department: yup.string(),
  emails: yup
    .array()
    .of(
      yup.object({
        type: yup.string().oneOf(TYPES),
        value: yup.string().email().required().label('Email'),
      })
    )
    .min(1),
  phones: yup.array().of(
    yup.object({
      type: yup.string().oneOf(TYPES),
      value: yup.string().required().label('Phone'),
    })
  ),
  socials: yup.array().of(
    yup.object({
      type: yup.string().oneOf(TYPES),
      value: yup.string().required().label('URL'),
    })
  ),
  addresses: yup.array().of(
    yup.object({
      type: yup.string().oneOf(TYPES),
      value: yup.string().required().label('Address'),
    })
  ),
})

function CreateCardholder({ title, onClose, user = {} }) {
  const { currentUser } = useAuth()
  const { departments, setDepartments } = useCardHolders()
  const [departmentQuery, setDepartmentQuery] = useState()
  const [filteredDepartments, setFilteredDepartments] = useState([
    ...departments,
  ])
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')

  const getPhoneDialCode = (phone) => {
    if (!phone) return ''
    const countryCode = CountryCodes.find((code) => {
      return phone.includes(code.dial_code)
    })
    return countryCode?.dial_code
  }

  const getValue = (phone) => {
    if (!phone) return ''
    const countryCode = CountryCodes.find((code) => {
      return phone.includes(code.dial_code)
    })
    if (!countryCode) return ''
    const index = phone.indexOf(countryCode.dial_code)

    if (index !== -1) {
      const extractedStr = phone.substring(countryCode.dial_code.length)
      return extractedStr
    } else return ''
  }

  const initialValues = {
    prefix: user.prefix || '',
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    jobTitle: user.jobTitle || '',
    organisationName:
      currentUser.account.organisationName || user.organisationName,
    // license: user.license,
    // department: user.department || '',
    emails: user.emails?.length ? user.emails : [{ value: user.email } || ''],
    phones: user.phones?.length
      ? user.phones.map((phone) => ({
          ...phone,
          value: getValue(phone.value),
          dialCode: getPhoneDialCode(phone.value),
        }))
      : user.phone
      ? [
          {
            value: getValue(user.phone),
            dialCode: getPhoneDialCode(user.phone),
          },
        ]
      : [],
    socials: user.socials || [],
    addresses: user.addresses || [],
  }

  const handleSubmit = async (form) => {
    setProcessing(true)
    setError('')

    form.phones = form.phones.map((phone) => ({
      type: phone.type,
      value: phone.dialCode + phone.value,
    }))

    const { error, response } = isEmptyJSON(user)
      ? await Api('/user', 'post', { payload: form })
      : await Api('/user/update_user', 'put', {
          payload: { _id: user._id, ...form },
        })
    setProcessing(false)
    if (error) return setError(error.message)

    showToastMessage(
      isEmptyJSON(user)
        ? 'User successfully created'
        : 'User successfully updated'
    )
    onClose(response?.data?.user)
  }

  const addNewDepartment = async (setFieldValue) => {
    const { error, response } = await Api('/department', 'post', {
      payload: { name: departmentQuery },
    })
    if (response) {
      departments.push(response.data?.department)
      setDepartments([...departments])
      setFilteredDepartments([...departments])
      setFieldValue('department', response.data?.department?._id)
    }
  }

  const handlerDepartmentQuery = (e) => {
    setDepartmentQuery(e.target.value)
    if (!e.target.value) return setFilteredDepartments(departments)

    const filtered = departments.filter((item) =>
      item.name.includes(e.target.value)
    )
    setFilteredDepartments(filtered)
  }

  return (
    <>
      <h3 class='font-heading tw-text-xl md:tw-text-2xl tw-mb-4'>
        {title}
        {/* What details should this cardholder share? */}
      </h3>
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={handleSubmit}
        validationSchema={CreateCardHolder}
        isInitialValid={false}
      >
        {({ isValid, setFieldValue, values }) => (
          <Form className='tw-max-w-screen-sm tw-mx-auto'>
            {/* <div class='tw-my-4 tw-gap-4 md:tw-gap-9 tw-grid md:tw-grid-cols-2'>
              <CDInputOutline label={'Prefix'} name={'prefix'} />
            </div> */}
            <Prefix className={'tw-my-7'} />
            <div class='tw-my-4 tw-gap-4 md:tw-gap-9 tw-grid md:tw-grid-cols-2'>
              <CDInputOutline
                label={'First name'}
                name={'firstName'}
                required
              />
              <CDInputOutline label={'Last name'} name={'lastName'} required />
            </div>
            <div class='tw-my-4 tw-gap-4 md:tw-gap-9 tw-grid md:tw-grid-cols-2'>
              <CDInputOutline
                label={'Job Role or Position'}
                name={'jobTitle'}
                required
              />
              <CDInputOutline
                label={'Company or Organisation'}
                name={'organisationName'}
                required
                readOnly={currentUser.role != ROLE.ADMIN}
              />
            </div>
            {/* <div class='tw-my-4'>
              <CDInputOutline label={'License'} name={'license'} />
            </div> 
            <div class='tw-my-4 tw-gap-4 md:tw-gap-9'>
              <div>
                <Combobox
                  as={'div'}
                  class='tw-relative'
                  value={
                    departments.find((item) => item._id == values.department)
                      ?.name
                  }
                  onChange={(value) => setFieldValue('department', value)}
                >
                  <Combobox.Label class='tw-text-navyBlue tw-text-regular tw-leading-regular tw-flex tw-justify-between'>
                    <div class='tw-flex tw-items-center tw-gap-[5px]'>
                      Department
                    </div>
                  </Combobox.Label>
                  <Combobox.Input
                    class='tw-leading-regular tw-text-regular tw-px-[10px] tw-py-[5px] tw-w-full tw-rounded-regular tw-border tw-border-lavender tw-mt-[8px] tw-focus:border-electricGreen tw-focus:ring-transparent'
                    onChange={handlerDepartmentQuery}
                  />
                  <Combobox.Button>
                    <ArrowDropdownIcon class='tw-w-[9px] tw-h-[10px] tw-absolute tw-top-[40px] tw-right-[10px]' />
                  </Combobox.Button>
                  <Combobox.Options class='tw-max-h-36 tw-overflow-y-scroll tw-absolute tw-z-50 tw-leading-regular tw-text-regular tw-w-full tw-rounded-regular tw-border tw-border-lavender tw-mt-[8px] tw-focus:border-electricGreen tw-focus:ring-transparent tw-bg-white'>
                    {filteredDepartments.length ? (
                      filteredDepartments.map((department) => (
                        <Combobox.Option
                          key={department._id}
                          value={department._id}
                          class='tw-flex tw-items-center tw-leading-regular hover:tw-bg-lavender tw-cursor-pointer tw-px-[10px] tw-py-[5px]'
                        >
                          {department.name}
                        </Combobox.Option>
                      ))
                    ) : (
                      <>
                        <Combobox.Option
                          onClick={() => addNewDepartment(setFieldValue)}
                          class='tw-flex tw-items-center tw-leading-regular hover:tw-bg-lavender tw-cursor-pointer tw-px-[10px] tw-py-[5px]'
                        >
                          <AddIcon />
                          Add new department "{departmentQuery}"
                        </Combobox.Option>
                        <div class='tw-p-[10px] tw-text-center'>
                          No department found
                        </div>
                      </>
                    )}
                  </Combobox.Options>
                </Combobox>
              </div>
            </div> */}

            <ListInputField
              name={'emails'}
              label={'Email(s)'}
              btnLabel={'email'}
              type={'email'}
              required
              className={'tw-my-7'}
            />
            <ListInputFieldPhone
              name={'phones'}
              label={'Phone numbers'}
              type={'tel'}
              btnLabel={'number'}
              className={'tw-my-7'}
            />

            <ListInputField
              name={'socials'}
              label={'Add any URL, including for social profiles'}
              btnLabel={'url'}
              className={'tw-my-7'}
            />

            <ListInputField
              name={'addresses'}
              label={'Address'}
              btnLabel={'address'}
              className={'tw-my-7'}
            />

            <button
              class={`tw-rounded-regular focus:tw-outline-none focus:tw-ring-transparent tw-w-full tw-text-regular tw-py-[10px] tw-px-[10px] ${
                !isValid || processing
                  ? 'tw-bg-grey tw-text-lightGrey'
                  : 'tw-bg-electricGreen tw-text-white'
              }`}
              type='submit'
              disabled={!isValid || processing}
            >
              {processing ? (
                <Loader />
              ) : isEmptyJSON(user) ? (
                'Create card'
              ) : (
                'Update card'
              )}
            </button>
            <div className='error'>{error}</div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CreateCardholder
