import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import ComponentLoader from './component-loader'

const OrganisationOnboard = ComponentLoader(
  lazy(() => import('../layouts/authentication/register/organisation/onboard'))
)

const OnboardOrganisationRoutes = [
  {
    path: 'register',
    element: <OrganisationOnboard />,
  },
  {
    path: '*',
    element: <Navigate to={'register'} />,
  },
]

export default OnboardOrganisationRoutes
