import React from 'react'

function Passback({ pass }) {
  const systemFields = [
    {
      key: 'your_details',
      label: 'Your details',
      value: `${pass.userId.fullName}
      ${pass.userId.email}
      `,
      readOnly: true,
    },
    {
      key: 'issuer_info',
      label: 'Issuer information',
      value: `Email: ${pass.userId.email}
      Company: ${pass.accountId.organisationName}
      Phone: ${pass.accountId.phoneNumber || pass.userId.phone}
      `,
    },
    {
      key: 'terms',
      label: 'Terms of use',
      value: `1. Redeem your loyalty card to get a points on your every visit.
      2. Loyalty card expiration date is unlimited.
      3. Loyalty cards cannot be exchanged, returned, replaced or purchased for cash.
      4. Loyalty card cannot be returned to the issuer and cannot be combined with other cards.
      5. The company has the right to refuse to provide services.`,
    },
    {
      key: 'powered_by',
      value: `POWERED BY LoyalIdeas`,
      readOnly: true,
    },
  ]
  return (
    <>
      <div class='tw-mt-3 tw-w-52 tw-mx-auto'>
        <div className='tw-border-b tw-px-1.5'>
          <div
            className='tw-shadow'
            style={{ backgroundColor: pass.designId?.backgroundColor }}
          >
            <div class='tw-flex tw-justify-between tw-items-start tw-p-1'>
              <div class='tw-flex tw-w-1/2 tw-truncate tw-items-center'>
                <img
                  className='tw-w-3 tw-h-3'
                  src={
                    pass.designId?.logoImage || 'https://placehold.co/600x600'
                  }
                  alt='logo'
                />
                {/* <h1 class='tw-font-medium tw-text-[6px] tw-ml-1'>New Pass</h1> */}
                <div />
              </div>
              {/* <div
              // class={`w-1/2 truncate ${
              // //   pass.headerFields[0].textAlignment.includes('Left')
              //     // ? 'text-left'
              //     // : 'text-right'
              // }`}
              >
                <div class='tw-font-medium tw-text-[4px] '>
                  Customer Name
                  {/* {pass.headerFields[0].label} 
                </div>
                <div class='tw-font-light tw-text-[6px]'>
                  {pass.userId?.fullName}
                </div>
              </div> */}
            </div>
            <div class=''>
              <img
                className='tw-w-full tw-object-cover tw-max-h-12'
                src={
                  pass.designId?.stripImage || 'https://placehold.co/600x600'
                }
                alt='cover'
              />
            </div>
            <div class='tw-flex tw-justify-between tw-items-start tw-p-1'>
              <div
              // class={`w-1/2 truncate ${
              //   pass.secondaryFields[0].textAlignment.includes('Left')
              //     ? 'text-left'
              //     : 'text-right'
              // }`}
              >
                <h1 class='tw-font-medium tw-text-[4px]'>Customer Name</h1>
                <h1 class='tw-font-light tw-text-[6px]'>
                  {pass.userId?.fullName}
                </h1>
                <div />
              </div>
              <div
                className='tw-text-right'
                // class={`w-1/2 truncate ${
                //   pass.secondaryFields[0].textAlignment.includes('Left')
                //     ? 'text-left'
                //     : 'text-right'
                // }`}
              >
                <h1 class='tw-font-medium tw-text-[4px]'>Points</h1>
                <h1 class='tw-font-light tw-text-[6px]'>
                  {pass.ponit_balance}
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div class='tw-text-center'>
          <div class='tw-font-bold tw-mt-3.5'>
            {pass.accountId?.organisationName}
          </div>
          <div class='tw-font-light tw-text-xs tw-text-gray-500'>
            updated 4 days ago
          </div>
        </div>
      </div>
      <div class='tw-mt-4 tw-bg-white tw-border tw-shadow-md tw-rounded-lg tw-px-2 tw-py-3 tw-mb-6 tw-w-1/4 tw-m-auto'>
        <>
          {systemFields.map((field) => (
            <>
              <div>
                <div
                  class=' tw-text-sm tw-mt-5 tw-mb-2'
                  style={{ fontWeight: 'bold' }}
                >
                  {field.label}
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: field.value }}
                  class=' tw-text-sm tw-mb-2 tw-whitespace-pre-line'
                ></div>
              </div>
              <hr />
            </>
          ))}
          {/* <div
            class=' tw-text-sm tw-mt-5 tw-mb-2 '
            style={{ fontWeight: 'bold' }}
          >
            Your Details:
          </div>
          <div class=' tw-text-sm tw-break-words tw-mb-2'>
            <div>Zia Arsalan </div>
            <div>Digi Service </div>
            <div>Devtronics</div>
            <div>zia20isys@gmail.com</div>
          </div>
          <hr />
          <div
            class=' tw-text-sm tw-mt-5 tw-mb-2 '
            style={{ fontWeight: 'bold' }}
          >
            Update Your Details:
          </div>
          <div class=' tw-text-sm tw-break-words tw-mb-2'>
            login to your account to update your details via this link -{' '}
            <a href='https://app.loyalideas.com'>https://app.loyalideas.com</a>
          </div>
          <hr />
          <div
            class=' tw-text-sm tw-mt-5 tw-mb-2 '
            style={{ fontWeight: 'bold' }}
          >
            Refer a friend
          </div>
          <div class=' tw-text-sm tw-break-words tw-mb-2'>
            https://app.rupertsamuel.com/registration/tool/gt38514b/ca2f17c5?referral=us2fc4e9
          </div>
          <hr />
          <div
            class=' tw-text-sm tw-mt-5 tw-mb-2'
            style={{ fontWeight: 'bold' }}
          >
            Notification message
          </div>
          <div class=' tw-text-sm  tw-mb-2'>
            This is the first notification message
          </div>
          <hr />
          <div
            class=' tw-text-sm tw-mt-5 tw-mb-2 '
            style={{ fontWeight: 'bold' }}
          >
            Notification history
          </div>
          <div class=' tw-text-sm tw-mb-2 tw-break-words'>
            https://app.rupertsamuel.com/pass/pa492f36
          </div>
          <hr />
          <div
            class=' tw-text-sm tw-mt-5 tw-mb-2'
            style={{ fontWeight: 'bold' }}
          >
            Terms of use
          </div>
          <div class=' tw-text-xs tw-mb-2 '>
            1. Redeem a coupon to get a discount on your first visit. <br /> 2.
            Coupon expiration date is unlimited. <br />
            3. Coupons cannot be exchanged, returned, replaced or purchased for
            cash. <br /> 4. Coupons cannot be returned to the issuer and cannot
            be combined with other cards. <br />
            5. The company has the right to refuse to provide services.
          </div>
          <hr />
          <div
            class=' tw-text-sm tw-mt-5 tw-mb-2'
            style={{ fontWeight: 'bold' }}
          >
            Issuer information
          </div>
          <div class=' tw-text-sm tw-mb-2'>
            <div>Email: name@email.com </div>
            <div> Company: www.domain.com </div>
            <div>phone: +123456789 </div>
          </div>
          <hr />

          <div class=' tw-text-sm tw-mb-2 tw-mt-2'>POWERED BY Loyal Ideas</div>
          <hr />
          <div
            class=' tw-text-sm tw-mt-5 tw-mb-2'
            style={{ fontWeight: 'bold' }}
          >
            Business Card Updated:{' '}
          </div>
          <div className='tw-text-sm tw-mb-2'>05/03/2024, 5:29PM</div>
          <hr /> */}
        </>
      </div>
    </>
  )
}

export default Passback
