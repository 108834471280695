import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import ClaimCard from 'layouts/public/employee-onboard/useronboard'
import EnterpriseOnboardLayout from 'layouts/public/enterprise-onboard'
import Download from 'layouts/authentication/register/personal/download'

const EmployeeOnBoard = ComponentLoader(
  lazy(() => import('../layouts/public/employee-onboard/onboard-form'))
)

const Login = ComponentLoader(
  lazy(() => import('../layouts/public/employee-onboard/login'))
)

const GeneratePass = ComponentLoader(
  lazy(() => import('../layouts/public/genreate_pass'))
)

const OnboardEnterprise = ComponentLoader(
  lazy(() => import('../layouts/public/employee-onboard/login'))
)

// const Demos = ComponentLoader(
//   lazy(() => import('../layouts/authentication/register/personal/demo'))
// )
// const Download = ComponentLoader(
//   lazy(() => import('../layouts/authentication/register/personal/download'))
// )

const PublicRoutes = [
  {
    path: 'claim-card',
    element: <ClaimCard />,
    children: [
      {
        path: ':accountId',
        index: true,
        element: <EmployeeOnBoard />,
      },
      {
        path: 'set-password',
        element: <Login />,
      },
    ],
  },
  {
    path: 'generate-pass',
    element: <GeneratePass />,
  },
  {
    path: 'create-password',
    element: <EnterpriseOnboardLayout />,
    children: [
      {
        path: '',
        index: true,
        element: <OnboardEnterprise />,
      },
    ],
  },
  {
    path: 'demo',
    // element: <Demos />,
  },
  {
    path: 'pass/:passCode',
    element: <Download />,
  },
]

export default PublicRoutes
