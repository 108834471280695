import React, { useEffect, useState } from 'react'
import PassPreview from '../component/pass-preview'
import { useParams } from 'react-router-dom/dist'
import Api from 'utils/api'
import addtowalletAndroid from '../../../../assets/images/addto walletpasses.png'
import addtowalletApple from '../../../../assets/images/add-to-apple-wallet-logo.png'
import Passback from './passback'
import { Tooltip } from 'react-tooltip'
import env from '../../../../config'

const DEVICES = {
  APPLE: 'apple',
  ANDROID: 'android',
}

function Download() {
  const { passCode } = useParams()
  const [pass, setPass] = useState(false)
  const [showPassback, setShowPassback] = useState(false)
  const [device, setDevice] = useState(DEVICES.APPLE)

  const values = pass
  ? {
      backgroundColor: pass.designId.backgroundColor,
      logoImage: pass.designId.logoImage,
      stripImage: pass.designId.stripImage,
      fullName: pass.userId.fullName,
      points: pass.ponit_balance,
      passCode: pass.passCode,
    }
  : undefined


  const walletURL =
    env.API_URL +
    '/v1/pass/apple/pkpass?pass_code=' +
    passCode +
    '&device_name=' +
    device

  useEffect(() => {
    const fetchPassByCode = async () => {
      const { response } = await Api(
        `/pass/passByCode?passCode=${passCode} `,
        'get',
        { notify: true }
      )

      if (response) {
        setPass(response.data)
      } else {
        // console.error('Failed to fetch pass data:', error)
      }
    }

    fetchPassByCode()
  }, [passCode])

  const togglePassback = () => {
    setShowPassback(!showPassback)
  }

  useEffect(() => {
    const userAgent = navigator.userAgent
    if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream)
      setDevice(DEVICES.APPLE)
    else setDevice(DEVICES.ANDROID)
  }, [])

  

  return (
    <>
      {/* {console.log(pass)} */}
      <div className='tw-flex tw-justify-center'>
        <a 
        href={
            device == DEVICES.APPLE
              ? walletURL
              : `https://walletpass.io?u=${encodeURIComponent(walletURL)}`
          }>

        <img
          src={
            device == DEVICES.APPLE ? addtowalletApple : addtowalletAndroid
          }
          alt=''
          className='tw-w-36 tw-mr-7 tw-h-auto tw-mt-3 tw-mb-3 tw-cursor-pointer'
        />
        </a>

        <div
          data-tooltip-id='detail'
          data-tooltip-content={showPassback ? 'Pass' : 'Details'}
          className=' tw-p-1  tw-rounded-full tw-cursor-pointer tw-w-fit '
        >
          <svg
            onClick={togglePassback}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='tw-w-8 tw-h-8 tw-ml-32 tw-mt-5 tw-cursor-pointer'
          >
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              d='M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
            />
          </svg>
          <Tooltip className='tw-z-50 tw-ml-16' place='top' id='detail' />
        </div>
      </div>
      <div className='tw-flex tw-justify-center tw-items-center'>
        {!showPassback && pass ? <PassPreview values={values} /> : null}
      </div>
      {showPassback && pass && <Passback pass={pass} />}
    </>
  )
}

export default Download
