import React from 'react'

function MobileDevices({ row }) {
  return (
    <div class='md:tw-hidden tw-w-full'>
      <div class='tw-flex tw-bg-white tw-p-[10px] tw-cursor-pointer'>
        <div
          key={row.mobile_device.email || row.mobile_device.date}
          class='tw-w-full tw-overflow-hidden tw-whitespace-nowrap'
        >
          {row.mobile_device.name && (
            <div class='tw-flex tw-gap-[15px]'>
              <div class='tw-flex tw-text-[12px] tw-overflow-hidden'>
                <span class='tw-mr-[3px]'>Name :</span>
                <div class='tw-text-[12px] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap'>
                  {row.mobile_device.name}
                </div>
              </div>
              <div class='tw-flex tw-text-[12px] tw-overflow-hidden'>
                <span class='tw-mr-[3px]'></span>
                <div class='tw-border tw-border-lavender tw-px-[4px] md:tw-px-0 tw-rounded-[3px] md:tw-border-none tw-text-[12px] tw-text-[#959595]'>
                  {row.mobile_device.point}
                </div>
              </div>
            </div>
          )}
          {Object.entries(row.mobile_device).map(
            ([key, value]) =>
              key != 'name' &&
              key != 'point' && (
                <div class='tw-flex tw-text-[12px] tw-overflow-hidden'>
                  <span class='tw-mr-[3px] tw-capitalize'>{key} :</span>
                  <div class='tw-text-[12px] tw-text-[#959595] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap'>
                    {value}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  )
}

function TableData({ data, headers }) {
  return (
    <div class='tw-divide-y-[1px] tw-divide-[#E7E7E7] tw-divide-dashed'>
      {data.map((row) => (
        <div class='tw-py-[8px]'>
          <div class='tw-group md:hover:tw-bg-[#8AB9A014] tw-rounded-[5px] tw-px-[10px] tw-py-[7px] tw-flex tw-cursor-pointer'>
            {headers.map((header) => (
              <>{row[header]}</>
            ))}
            <MobileDevices row={row} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default TableData
