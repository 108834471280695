import React, { useEffect, useState } from 'react'
import images from 'assets/images/Guidelines_page-0001.jpg'
import { Outlet, useNavigate } from 'react-router-dom'
import Api from 'utils/api'
import sideimg from '../../../assets/images/Abstract Creative Idea Brain Bulb Logo (1).png'

function Useronboard() {
  const pathname = window.location.pathname.split('/')
  const navigate = useNavigate()
  const [pass, setPass] = useState()

  const getUser = async () => {
    const { response } = await Api('/pass', 'get')
    if (response) setPass(response.data)
  }

  const onLoad = () => {
    getUser()
  }

  useEffect(onLoad, [])

  return (
    <div className='tw-grid md:tw-h-screen tw-items-stretch md:tw-grid-cols-2 md:tw-p-0 tw-p-5'>
      <div className='md:tw-flex tw-items-center tw-justify-center d:tw-h-screen tw-hidden tw-bg-[rgb(44,48,83)]'>
        <div className='tw-fixed tw-top-20 tw-left-20'>
          {pathname.length > 2 && (
            <div onClick={() => navigate(-1)} className='tw-block tw-mb-4'>
              <span className='tw-cursor-pointer' style={{ color: 'white' }}>
                ← back
              </span>
            </div>
          )}
        </div>
        <div className='tw-block '>
          {/* <CardlyWhite /> */}
          {/* {pass?.design?.logoImage && (
            <img
              src={pass?.design?.logoImage}
              className='tw-max-w-[120px]'
              alt=''
            />
          )} */}
          <img src={sideimg} alt='' />
        </div>
      </div>

      <div class='tw-flex md:tw-items-center md:tw-justify-center md:tw-px-4 tw-py-8'>
        <div class='md:tw-max-w-sm tw-w-full'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Useronboard
