import React, { useState } from 'react'
import { Combobox, Menu } from '@headlessui/react'
import CDInputOutline from 'components/cd-input-outline'
import { ErrorMessage, Form, Formik, useFormikContext } from 'formik'
import AddFilledIcon from 'icons/add-filled'
import AddIcon from 'icons/add'
import ArrowDropdownIcon from 'icons/arrow-dropdown'
import RemoveIcon from 'icons/remove'
import * as yup from 'yup'
import Loader from 'components/loader'
import Api from 'utils/api'
import { useCardHolders } from 'layouts/admin/cardholder/card-holders.context'
import { useAuth } from 'context/auth'
import { isEmptyJSON, showToastMessage } from 'utils'
import ListInputField from 'components/list-input-field'
import Prefix from 'components/prefix'
import { ROLE } from 'enums/user'
import ListInputFieldPhone from 'components/list-input-field-phone'
import CountryCodes from 'enums/countrycode'

const CreateTeamSchema = yup.object({
  firstName: yup.string().required().label('First name'),
  lastName: yup.string().required().label('Last name'),
  phone: yup.number().required().label('Phone number'),
  email: yup.string().required().label('Email adress'),
  password: yup.string().required().label('Password'),
  c_password: yup.string().required().label('Confirm password'),
})

function CreateTeam({ title, onClose, user = {} }) {
  const { currentUser } = useAuth()

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')

  const getPhoneDialCode = (phone) => {
    if (!phone) return ''
    const countryCode = CountryCodes.find((code) => {
      return phone.includes(code.dial_code)
    })
    return countryCode?.dial_code
  }

  const getValue = (phone) => {
    if (!phone) return ''
    const countryCode = CountryCodes.find((code) => {
      return phone.includes(code.dial_code)
    })
    if (!countryCode) return ''
    const index = phone.indexOf(countryCode.dial_code)

    if (index !== -1) {
      const extractedStr = phone.substring(countryCode.dial_code.length)
      return extractedStr
    } else return ''
  }

  const initialValues = {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
    phone: user.phone || '',
    password: user.password || '',
    c_password: user.c_password || '',
  }

  const handleSubmit = async (form) => {
    setProcessing(true)
    setError('')

    const { error, response } = isEmptyJSON(user)
      ? await Api('/user', 'post', { payload: form })
      : await Api('/user/update_user', 'put', {
          payload: { _id: user._id, ...form },
        })
    setProcessing(false)
    if (error) return setError(error.message)

    showToastMessage(
      isEmptyJSON(user)
        ? 'User successfully created'
        : 'User successfully updated'
    )
    onClose(response?.data?.user)
  }

  return (
    <>
      <h3 class='font-heading tw-text-xl md:tw-text-2xl tw-mb-4'>
        {title}
        {/* What details should this cardholder share? */}
      </h3>
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={handleSubmit}
        validationSchema={CreateTeamSchema}
        isInitialValid={false}
      >
        {({ isValid, setFieldValue, values }) => (
          <Form className='tw-max-w-screen-sm tw-mx-auto'>
            <div class='tw-my-4 tw-gap-4 md:tw-gap-9 tw-grid md:tw-grid-cols-2'>
              <CDInputOutline
                label={'First name'}
                name={'firstName'}
                required
              />
              <CDInputOutline label={'Last name'} name={'lastName'} required />
            </div>

            <div>
              <CDInputOutline
                label={'Email adress'}
                name={'email'}
                required
                // readOnly={currentUser.role != ROLE.ADMIN}
              />
            </div>
            <div class='tw-my-4 '>
              <CDInputOutline label={'Phone number'} name={'phone'} required />
            </div>
            <div class='tw-my-4 tw-gap-4 md:tw-gap-9 tw-grid md:tw-grid-cols-2'>
              <CDInputOutline
                label={'Password'}
                name={'password'}
                type={'password'}
                required
              />
              <CDInputOutline
                label={'Confirm password'}
                name={'c_password'}
                type={'password'}
                required
                // readOnly={currentUser.role != ROLE.ADMIN}
              />
            </div>

            <button
              class={`tw-rounded-regular focus:tw-outline-none focus:tw-ring-transparent tw-w-full tw-text-regular tw-py-[10px] tw-px-[10px] ${
                !isValid || processing
                  ? 'tw-bg-grey tw-text-lightGrey'
                  : 'tw-bg-electricGreen tw-text-white'
              }`}
              type='submit'
              disabled={!isValid || processing}
            >
              Create Team
              {/* {processing ? (
                <Loader />
              ) : isEmptyJSON(user) ? (
                'Create card'
              ) : (
                'Update card'
              )} */}
            </button>
            <div className='error'>{error}</div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CreateTeam
