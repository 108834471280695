import html2canvas from 'html2canvas'
import { toast } from 'react-toastify'
import { getAuth } from './auth-helper'
import { PASS_VIEW } from 'enums'

function vcfText({
  prefix,
  firstName,
  lastName,
  organisationName,
  email,
  phone,
  phones,
  jobTitle,
  photo,
  companyURL,
  linkedInURL,
  socials,
  addresses,
  personalAccount,
}) {
  const getFullName = () => {
    if (prefix) return prefix + ' ' + firstName + ' ' + lastName
    return firstName + ' ' + lastName
  }

  const getName = () => {
    if (prefix) return `${lastName};${firstName};;${prefix};`
    return `${lastName};${firstName};;;`
  }

  const filterArray = () => {
    const seenValues = {}

    for (const item of phones) {
      const value = item.value

      if (!seenValues[value]) {
        seenValues[value] = true
        vcfPhones.push(item)
      }
    }
  }

  let vcfPhones = []
  if (phones) filterArray()
  if (
    phone &&
    !vcfPhones.find((item) => item.value == phone) &&
    !phone.includes('undefined')
  )
    vcfPhones.push({ label: 'WORK', value: phone })

  let str = `BEGIN:VCARD
VERSION:3.0
ORG:${organisationName}
FN:${getFullName()}
N:${getName()}
TITLE:${jobTitle}
EMAIL;TYPE=:${email}
PHOTO;VALUE=URL;TYPE=JPEG:${photo}
URL:${companyURL || ''}`

  if (personalAccount)
    str = str + `\nURL;TYPE=Saved via Cardly:https://www.cardlysa.com`

  if (linkedInURL) str = str + `\nX-SOCIALPROFILE;TYPE=linkedin:${linkedInURL}`

  if (socials)
    socials.forEach(({ type, value }) => {
      str = str + `\nX-SOCIALPROFILE;TYPE=${type || 'social'}:${value}`
    })

  if (addresses)
    addresses.forEach(({ type, value }) => {
      str = str + `\nADR;TYPE=${type || 'WORK'}:;;${value}`
    })

  // if (phone) str = str + `\nTEL:${phone}`;

  if (vcfPhones)
    vcfPhones.forEach(({ type, value }) => {
      if (value.includes('undefined')) return
      str = str + `\nTEL;TYPE=${type || 'WORK'}:${value}`
    })

  str = str + `\nEND:VCARD`

  return str
}

function getLuminance(color) {
  const r = parseInt(color.slice(1, 3), 16)
  const g = parseInt(color.slice(3, 5), 16)
  const b = parseInt(color.slice(5, 7), 16)

  return (0.299 * r + 0.587 * g + 0.114 * b) / 255
}

// Function to determine the contrast ratio between two colors
function getContrastRatio(color1, color2) {
  const luminance1 = getLuminance(color1)
  const luminance2 = getLuminance(color2)

  const brighter = Math.max(luminance1, luminance2)
  const darker = Math.min(luminance1, luminance2)

  return brighter / (darker + 3)
}

// Function to invert text color based on background color
function invertTextColor(background) {
  const textColor =
    getContrastRatio(background, '#000000') >
    getContrastRatio(background, '#FFFFFF')
      ? '#000000'
      : '#FFFFFF'
  return textColor
}

function isDark(background) {
  return (
    getContrastRatio(background, '#000000') >
    getContrastRatio(background, '#FFFFFF')
  )
}

const showToastMessage = (message) => {
  toast(message, {
    position: toast.POSITION.TOP_CENTER,
    className: 'toast-message',
  })
}

const formateDate = (date) => {
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

const isEmptyJSON = (json) => JSON.stringify(json) === '{}'

const getFile = (dataURL) => {
  const base64Data = dataURL.split(',')[1]
  const mimeType = 'image/png' // Adjust MIME type as needed
  const byteCharacters = atob(base64Data)
  const byteArrays = []
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i))
  }
  const blob = new Blob([new Uint8Array(byteArrays)], { type: mimeType })

  // Create File from Blob
  const fileName = 'croppedImage.png' // Desired filename
  const file = new File([blob], fileName, { type: mimeType })

  // Perform further actions with the File object (e.g., upload, save)
  console.log('Converted File:', file)
  return file
}

const currencyFormat = (amount) => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD', // Change this to your desired currency code
    minimumFractionDigits: 2,
  })
  return currencyFormatter.format(amount)
}

const handleConvertToPNG = async (svg) => {
  const element = document.getElementById(svg)

  if (!element) return

  const canvas = await html2canvas(element, {
    backgroundColor: 'transparent',
    useCORS: true, // Enable CORS support if necessary
  })
  const image = new Promise((resolve) => {
    canvas.toBlob((blob) => {
      const reader = new FileReader()
      reader.onload = () => {
        const dataUrl = reader.result
        resolve(dataUrl)
      }
      reader.readAsDataURL(blob)
    })
    // const url = window.URL.createObjectURL(blob)
    // const a = document.createElement('a')
    // a.href = url
    // a.download = 'converted.png'
    // a.click()
    // window.URL.revokeObjectURL(url)
  })
  return image
}

const getPassURL = (passType) => {
  // const url = 'https://api.cardlysa.com/api/v1/pass/apple?token='

  const queryParams = new URLSearchParams(window.location.search)
  const token = getAuth().token || queryParams.get('token')

  if (passType == PASS_VIEW.ANDROID)
    return 'https://api.cardlysa.com/api/v1/pass/google?token=' + token

  return 'https://app.cardlysa.com/generate-pass?token=' + token
}

export {
  vcfText,
  invertTextColor,
  isDark,
  showToastMessage,
  isEmptyJSON,
  formateDate,
  getFile,
  currencyFormat,
  handleConvertToPNG,
  getPassURL,
}
