import { Menu } from '@headlessui/react'
import { useFormikContext } from 'formik'

const Prefixes = [
  { label: 'None', value: '' },
  { label: 'Dr. - Doctor', value: ' Dr.' },
  { label: 'Eng. - Engineer', value: ' Eng.' },
  { label: 'Prof. - Professor', value: ' Prof.' },
  { label: 'Miss', value: ' Miss' },
  { label: 'Mrs.', value: ' Mrs.' },
  { label: 'Ms.', value: ' Ms.' },
  { label: 'Mr.', value: ' Mr.' },
  {
    label: 'Assoc Dir. - (Associate Director abbreviation)',
    value: ' Assoc Dir.',
  },
  {
    label: 'CAO - Chief Analytics Officer or Chief Administrative Officer',
    value: ' CAO',
  },
  { label: 'CEO - Chief Executive Officer', value: ' CEO' },
  { label: 'CDO - Chief Data Officer', value: ' CDO' },
  { label: 'CFO - Chief Financial Officer', value: ' CFO' },
  { label: 'Chair - Chairperson', value: ' Chair' },
  { label: 'CHRO - Chief Human Resources Officer', value: ' CHRO' },
  { label: 'CIO - Chief Information Officer', value: ' CIO' },
  { label: 'CLO - Chief Legal Officer', value: ' CLO' },
  { label: 'CMO - Chief Marketing Officer', value: ' CMO' },
  { label: 'COO - Chief Operation Officer', value: ' COO' },
  { label: 'CPO - Chief People Officer', value: ' CPO' },
  { label: 'CTO - Chief Technology Officer', value: ' CTO' },
  { label: 'Dir. - Director', value: ' Dir.' },
  { label: 'ED - Executive Director', value: ' ED' },
  { label: 'EVP - Executive Vice President', value: ' EVP' },
  { label: 'MD - Managing Director.', value: ' MD.' },
  { label: 'MP - Managing Partner', value: ' MP' },
  { label: 'Pres. - President', value: ' Pres.' },
  { label: 'PM - Project Manager', value: ' PM' },
  { label: 'Sr. Mgr. - Senior Manager', value: ' Sr. Mgr.' },
  { label: 'SWE - Software Engineer', value: ' SWE' },
]

function Prefix({ className, fullWidth }) {
  const formik = useFormikContext()

  const renderMenu = () => (
    <Menu as={'div'} className='tw-relative tw-text-left tw-w-full'>
      <Menu.Button className='tw-inline-flex tw-w-full tw-absolute tw-top-[-15px] tw-h-[30px] tw-rounded-md tw-px-[0px] tw-py-[5px] tw-bg-white tw-text-regular tw-font-medium tw-text-gray-700 tw-focus:outline-none'>
        {formik.values.prefix}
      </Menu.Button>

      <Menu.Items className='tw-origin-top-right tw-h-36 tw-overflow-y-scroll tw-py-[5px] tw-absolute tw-left-0 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:outline-none tw-z-20 tw-transform tw-opacity-100 tw-scale-100'>
        {Prefixes.map((type) => (
          <Menu.Item
            key={type.value}
            as={'div'}
            className='tw-block tw-text-regular tw-leading-5 tw-text-gray-700  hover:tw-bg-gray-100 tw-focus:outline-none focus:tw-bg-gray-100 tw-transition tw-ease-in-out tw-duration-150'
          >
            {({ active }) => (
              <div
                onClick={() => formik.setFieldValue(`prefix`, type.value)}
                className={`${
                  active && 'tw-bg-electricGreen tw-text-white'
                } tw-px-[10px]`}
              >
                {type.label}
              </div>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  )

  return (
    <div className={className}>
      <div class='tw-text-navyBlue tw-leading-regular tw-flex tw-cursor-default tw-items-center tw-gap-[5px] tw-text-[15px] md:tw-text-[13px] tw-justify-between'>
        <div class='tw-flex tw-items-center tw-gap-[5px]'>
          <div>Prefix</div>
        </div>
      </div>
      <div
        class={`tw-flex tw-items-center tw-border ${
          fullWidth ? 'tw-w-full' : 'tw-w-1/2'
        } tw-border-slate-300 tw-my-2 tw-rounded-md`}
      >
        <div class='tw-cursor-pointer tw-px-[10px] tw-py-[5px] tw-flex tw-flex-none tw-items-center tw-w-full tw-h-8 tw-relative tw-text-center tw-text-slate-400 tw-text-sm'>
          {renderMenu()}
        </div>
      </div>
    </div>
  )
}

export default Prefix
