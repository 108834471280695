import React from 'react'

function RoleColumn({ role }) {
  return (
    <div class='tw-w-2/12 tw-items-center tw-hidden md:tw-flex tw-overflow-hidden'>
      <div class='tw-border tw-border-lavender tw-capitalize tw-px-[4px] md:tw-px-0 tw-rounded-[3px] md:tw-border-none tw-text-[12px] tw-text-[#959595]'>
        {role}
      </div>
    </div>
  )
}

export default RoleColumn
