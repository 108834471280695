import { Environment } from '../enums'

const env = {
  API_URL: 'http://localhost:8082/api',
  APP_URL: 'http://localhost:3000',
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = 'https://api.loyalideas.com/api'
  env.APP_URL = 'https://app.loyalideas.com'
}

export default env
