import Loader from 'components/loader'
import React, { useState } from 'react'
import { showToastMessage } from 'utils'
import Api from 'utils/api'

function ResendEmail({ user }) {
  const [processing, setProcessing] = useState(false)

  const ResendEmail = async () => {
    setProcessing(true)
    const { error, response } = await Api('/user/resend_invite', 'post', {
      payload: { user_id: user._id },
    })
    setProcessing(false)
    if (error) return showToastMessage(error.message)
    showToastMessage('Invitation Resend')
  }

  return processing ? (
    <span class='tw-text-medium tw-text-navyBlue tw-invisible group-hover:tw-visible hover:tw-cursor-pointer '>
      <Loader />
    </span>
  ) : (
    <span
      onClick={ResendEmail}
      class='tw-text-medium tw-text-navyBlue tw-invisible group-hover:tw-visible hover:tw-cursor-pointer tw-mr-3'
    >
      Resend
    </span>
  )
}

export default ResendEmail
