import Dialoguemodal from 'components/dialoguemodal'
import CreateCardholder from 'layouts/admin/cardholder/create-cardholder'
import React, { useState } from 'react'

function Edit({ user, updateList, idx }) {
  const [open, setOpen] = useState(false)

  const handleFormClose = (updates) => {
    setOpen(false)
    updateList(updates, idx)
  }
  return (
    <>
      <span
        onClick={() => setOpen(true)}
        class="tw-text-medium tw-text-navyBlue tw-invisible group-hover:tw-visible hover:tw-cursor-pointer tw-undefined"
      >
        Edit
      </span>
      <Dialoguemodal open={open} onClose={() => setOpen(false)}>
        <CreateCardholder user={user} onClose={handleFormClose} />
      </Dialoguemodal>
    </>
  )
}

export default Edit
