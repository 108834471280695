import Tendayleft from "icons/tendayleft";
import React from "react";
import { Link } from "react-router-dom";

function Name({ name }) {
  return <div className="tw-pt-[2px]">{name}</div>;
}

function ToolTip({ text, buttontext, name, link }) {
  return (
    // <div className="tw-mx-0">
    <div className=" tw-font-[600] tw-text-[10px] tw-text-[#B94B20] tw-leading-[22px] tw-px-[0px] tw-py-[6px] tw-rounded-[4px] tw-flex tw-gap-[3px] tw-max-w-[100px] md:tw-max-w-[155px]">
      {/* <div className="tw-text-[#B94B20CC]">GRACE</div> */}
      <div className="tw-text-[12px] tw-flex tw-gap-[2px]">
        {/* <div className="md:tw-block tw-hidden tw-whitespace-nowrap">
            days left
          </div> */}
      </div>
      <div className="tw-relative tw-flex tw-flex-wrap tw-items-center tw-group">
        <Name name={name} />
        <div className="tw-absolute tw-top-[35px] tw-w-[250px] md:tw-w-[350px] -tw-ml-[150px] tw-bg-red tw-pb-3 tw-z-50">
          <div className="tw-relative tw-px-5 tw-py-4 tw-flex-col tw-items-start tw-hidden tw-mb-6 group-hover:tw-flex tw-p-2 tw-bg-electricGreen tw-rounded-[12px]">
            <span className="tw-relative tw-z-10 tw-text-xs tw-leading-none tw-text-white tw-mb-3">
              {/* <div className="tw-mb-5 tw-font-normal">
                  Start your subscription in order to continue using Cardly’s
                  digital business card software services.
                </div> */}
              <div className="tw-font-semibold">{text}</div>
            </span>
            <Link
              to={link}
              className="tw-flex tw-px-[20px] tw-py-[7px] tw-gap-[10px] tw-rounded-[6px] tw-bg-white tw-text-electricGreen tw-text-sm hover:tw-cursor-pointer"
            >
              {buttontext}
            </Link>
            <div className="tw-w-3 tw-h-3 tw-rotate-45 tw-bg-electricGreen tw-absolute tw--top-[5px] tw-left-[150px]"></div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default ToolTip;
