import * as USER from './user'

const Environment = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

const PASS_VIEW = {
  APPLE: 'apple',
  ANDROID: 'android',
}

const GRACE_PERIOD_IN_DAYS = 30

export { USER, PASS_VIEW, Environment, GRACE_PERIOD_IN_DAYS }
