import React from 'react'

function ArrowDropdownIcon({ ...props }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      width='1em'
      height='1em'
      fill='%234B4B4B'
      class='tw-w-[9px] tw-h-[10px] tw-absolute tw-top-[0px] tw-right-[10px]'
      {...props}
    >
      <path
        fill-rule='evenodd'
        d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
      ></path>
    </svg>
  )
}

export default ArrowDropdownIcon
