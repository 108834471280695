import React, { useState } from "react";
import { useCardHolders } from "./team.context";
import DownloadIcon from "icons/download";
import Dialoguemodal from "components/dialoguemodal";
import Table from "components/table/table";
import NameColumn from "components/table/name-column";
import RoleColumn from "components/table/role-column";
import TextColumn from "components/table/text-column";
import StatusColumn from "components/table/status-column";
import QuickActionColumn from "components/table/action-column/action-column";
import FilterList from "components/filter-list";
import SearchList from "components/search-list";
import DownloadList from "components/download-list";
import { useAuth } from "context/auth";
import AddItem from "icons/additem";
import CreateTeam from "./create-team";
import { PLANS } from "enums/plans";
import DaysLeft from "components/days-left";
import ToolTip from "components/tooltip";

function Team() {
  const { setCardholders, currentUser } = useAuth();
  const { loading, users, setUsers, downloadExcel, searchUser } =
    useCardHolders();
  const [open, setOpen] = useState(false);

  const handleFormClose = (newCardHolder) => {
    setOpen(false);
    console.log(newCardHolder);
    if (!newCardHolder) return;
    users.push(newCardHolder);
    setUsers([...users]);

    setCardholders(users.length);
  };

  const updateList = (updates, idx) => {
    users[idx] = { ...updates };
    setUsers([...users]);
  };

  const userDeleted = (idx) => {
    users.splice(idx, 1);
    setUsers([...users]);
  };

  const getRows = () => {
    return users.map((user, idx) => ({
      Name: <NameColumn name={user.firstName + " " + user.lastName} />,
      Role: <RoleColumn role={user.role?.replace("_", " ")} />,
      Email: <TextColumn text={user.email} />,
      Status: <StatusColumn status={user.status} />,
      "Quick actions": (
        <QuickActionColumn
          user={user}
          idx={idx}
          updateList={updateList}
          userDeleted={userDeleted}
        />
      ),
      mobile_device: {
        name: user.firstName + " " + user.lastName,
        role: user.role,
        email: user.email,
      },
    }));
  };

  console.log(users, "usa");
  const isDesignAvailable =
    PLANS[currentUser?.account?.plan]?.users >= users.length;

  const isReportAvailable =
    PLANS?.[currentUser?.account?.plan]?.reports === true;
  return (
    <div>
      <div class="tw-flex tw-flex-col">
        <div id="table-header" class="">
          <div class="tw-flex tw-ml-[10px] tw-flex-col tw-items-start md:tw-flex-row md:tw-items-center">
            <h2 class="tw-text-[40px] tw-font-[600] font-heading tw-text-2xl md:tw-text-3xl">
              Team
              {/* <span class='tw-text-electricGreen'>.</span> */}
            </h2>
            <hr class="tw-border-gray-300 tw-mb-5 tw-mt-2" />
            <div class="tw-flex-grow tw-items-center tw-hidden md:tw-flex tw-justify-end">
              {isReportAvailable ? (
                <DownloadList />
              ) : (
                <div className="tw-flex tw-mr-5 tw-opacity-45 tw-text-regular tw-items-center hover:tw-text-electricGreen lg:tw-flex tw-cursor-not-allowed">
                  <ToolTip
                    name={"Download list (.xlsx)"}
                    buttontext={"Upgrade Account"}
                    link={"/subscribe"}
                    text={"Please upgrade your plan to get full access."}
                  />
                </div>
              )}

              {isDesignAvailable < users ? (
                <div
                  onClick={() => setOpen(true)}
                  // class='hover:tw-cursor-pointer tw-text-navyBlue hover:tw-text-electricGreen tw-hidden lg:tw-flex tw-gap-[5px] tw-px-[18px] tw-py-[10px] tw-items-center tw-relative tw-z-[1]'
                  className="tw-flex tw-cursor-pointer  tw-text-regular tw-items-center  hover:tw-text-electricGreen  lg:tw-flex "
                >
                  <AddItem />
                  <div className="tw-ml-1  tw-text-navyBlue">Add Team</div>
                </div>
              ) : (
                <div
                  // onClick={() => setOpen(true)}
                  className="tw-flex tw- tw-opacity-45 tw-text-regular tw-items-center hover:tw-text-electricGreen lg:tw-flex tw-cursor-not-allowed"
                >
                  {/* <div className="tw-ml-1 tw-text-navyBlue">Add Team</div> */}
                  <ToolTip
                    name={"Add Team"}
                    buttontext={"Upgrade Account"}
                    link={"/subscribe"}
                    text={"Please upgrade your plan to get full access."}
                  />
                  {/* <AddItem /> */}
                </div>
              )}
            </div>
          </div>
          <div class="tw-my-[20px] tw-flex tw-items-center">
            <div class="tw-flex">
              <SearchList search={searchUser} />
              <div class="tw-mt-auto tw-mb-auto tw-w-[2px] tw-h-[18px] tw-border tw-border-r-[1px] tw-border-[#E7E7E7]] tw-invisible lg:tw-visible"></div>
            </div>
            <FilterList />
          </div>
          <div class="tw-flex-grow tw-items-center tw-flex md:tw-hidden tw-justify-start tw-ml-[10px]">
            <div
              onClick={downloadExcel}
              class="hover:tw-cursor-pointer tw-text-navyBlue hover:tw-text-electricGreen tw-hidden lg:tw-flex tw-flex tw-gap-[5px] tw-px-[18px] tw-py-[10px] tw-items-center tw-relative tw-z-[1]"
            >
              <DownloadIcon />
              <span class="tw-text-regular tw-flex tw-items-center ">
                Download list (.xlsx)
              </span>
            </div>
          </div>
        </div>

        <div>
          <Table
            headers={["Name", "Role", "Email", "Status", "Quick actions"]}
            data={getRows()}
            loading={loading}
          />
        </div>
        <div class="tw-flex tw-justify-between"></div>
      </div>

      <Dialoguemodal open={open} onClose={() => setOpen(false)}>
        <CreateTeam onClose={handleFormClose} />
      </Dialoguemodal>
    </div>
  );
}

export default Team;
