const Free = {
  designs: 1,
  customers: 5,
  notifications: 2,
  redemptions: Infinity,
  emailSupport: true,
  members: 0,
  admins: 1,
  pdfDownload: false,
  publicAPIs: false,
  allTimeSupport: false,
  reports: false,
}

const Basic = {
  designs: 5,
  customers: 100,
  notifications: 50,
  redemptions: Infinity,
  emailSupport: true,
  members: 5,
  admins: 5,
  pdfDownload: true,
  publicAPIs: true,
  allTimeSupport: true,
  reports: true,
}

const FullAccess = {
  designs: Infinity,
  customers: Infinity,
  notifications: Infinity,
  redemptions: Infinity,
  emailSupport: true,
  members: Infinity,
  admins: Infinity,
  pdfDownload: true,
  publicAPIs: true,
  allTimeSupport: true,
  reports: true,
}

const PLANS = {
  free: Free,
  basic: Basic,
  fullAccess: FullAccess,
}

export { PLANS }
