import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { CardHoldersProvider } from './team.context'

function Teams({ role }) {
  const navigate = useNavigate()
  return (
    <CardHoldersProvider>
      <div onClick={() => navigate(-1)} class='tw-block tw-mb-4 tw-w-fit'>
        <span class='tw-cursor-pointer'>← back</span>
      </div>
      <Outlet />
    </CardHoldersProvider>
  )
}

export default Teams
