import React from 'react'
import { invertTextColor } from 'utils'

// function PassLogo({ color }) {
//   return (
//     <svg
//       version="1.1"
//       id="Layer_1"
//       xmlns="http://www.w3.org/2000/svg"
//       x="0"
//       y="0"
//       viewBox="0 0 429.6 687.2"
//     >
//       <path
//         d="M56.7 61.8c.7-1.9 1.3-3.6 1.8-5.1 100.4-39.6 212.1-39.7 312.6 0 .6 1.6 1.2 3.4 1.9 5.3 3.2 9.6 7.6 24 12 42.8 8.8 37.7 17.7 93.2 17.7 163.4s-8.9 125.7-17.7 163.4c-4.4 18.9-8.8 33.3-12 42.8-.7 2-1.3 3.8-1.9 5.3-100.4 39.6-212.2 39.6-312.6 0-.5-1.5-1.1-3.2-1.8-5.1-3.2-9.3-7.6-23.5-12-42.2C35.9 395.1 27 339.7 27 268.3S35.9 141.4 44.7 104c4.4-18.6 8.8-32.8 12-42.2z"
//         style={{
//           fill: "none",
//           stroke: color || "#000",
//           strokeWidth: 54,
//         }}
//       ></path>
//       <path
//         fill={color || "#000"}
//         d="M61.9 539.8c99.1 34 206.7 34 305.8 0 8.2 23.9-4.5 50.2-29.1 56.3-81.3 20.1-166.3 20.1-247.6 0-24.6-6.1-37.3-32.4-29.1-56.3z"
//         style={{
//           fillRule: "evenodd",
//           clipRule: "evenodd",
//         }}
//       ></path>
//       <path
//         fill={color || "#000"}
//         d="M92.1 626.3c80.6 20.1 164.8 20.1 245.4 0 6.1 24.5-8.8 49.6-33.7 53.7-58.9 9.6-119 9.6-177.9 0-25-4.1-39.9-29.2-33.8-53.7z"
//         style={{
//           fillRule: "evenodd",
//           clipRule: "evenodd",
//         }}
//       ></path>
//       <path
//         fill={color || "#000"}
//         d="M297.3 231.6c20.2 0 36.6 16.4 36.6 36.6 0 20.2-16.4 36.6-36.6 36.6-20.2 0-36.6-16.4-36.6-36.6-.1-20.2 16.3-36.6 36.6-36.6z"
//       ></path>
//     </svg>
//   );
// }

function generateColors(baseColor) {
  // Parse the base color as an RGB value
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.replace(/^#/, ''), 16)
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    }
  }

  // Convert the base color to RGB
  const baseRgb = hexToRgb(baseColor)

  // Calculate the hue offset for the overlapping colors
  const hueOffset = 60 // You can adjust this value

  // Calculate the hue values for the three colors
  const hue1 = (baseRgb.r + hueOffset) % 360
  const hue2 = (baseRgb.r - hueOffset + 360) % 360
  const hue3 = baseRgb.r

  // Convert HSL to RGB
  const hslToRgb = (h, s, l) => {
    const C = (1 - Math.abs(2 * l - 1)) * s
    const X = C * (1 - Math.abs(((h / 60) % 2) - 1))
    const m = l - C / 2

    let r, g, b

    if (0 <= h && h < 60) {
      r = C
      g = X
      b = 0
    } else if (60 <= h && h < 120) {
      r = X
      g = C
      b = 0
    } else if (120 <= h && h < 180) {
      r = 0
      g = C
      b = X
    } else if (180 <= h && h < 240) {
      r = 0
      g = X
      b = C
    } else if (240 <= h && h < 300) {
      r = X
      g = 0
      b = C
    } else {
      r = C
      g = 0
      b = X
    }

    return {
      r: Math.round((r + m) * 255),
      g: Math.round((g + m) * 255),
      b: Math.round((b + m) * 255),
    }
  }

  // Convert the hue values to RGB colors
  const color1 = `rgb(${hslToRgb(hue1, 0.7, 0.5).r}, ${
    hslToRgb(hue1, 0.7, 0.5).g
  }, ${hslToRgb(hue1, 0.7, 0.5).b})`
  const color2 = `rgb(${hslToRgb(hue2, 0.7, 0.5).r}, ${
    hslToRgb(hue2, 0.7, 0.5).g
  }, ${hslToRgb(hue2, 0.7, 0.5).b})`
  const color3 = `rgb(${hslToRgb(hue3, 0.7, 0.5).r}, ${
    hslToRgb(hue3, 0.7, 0.5).g
  }, ${hslToRgb(hue3, 0.7, 0.5).b})`

  return [color1, color2, color3]
}

function PassLogo({ color, width }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || 40}
      viewBox='0 0 20 13.45'
    >
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
            class='cls-1'
            fill={color || '#858585'}
            d='M8.7,4.8l1.66-2.15L10,2.36A9.63,9.63,0,0,0,8.17,1.18h0A3.64,3.64,0,0,0,6.24.8,4,4,0,0,0,3.39,2.49l-.05.06L1,5.52,1,5.59A4,4,0,0,0,.05,8.76C.24,10.11,1.34,11.12,2.49,12,4,13.16,5,13.56,6.12,13.42A3.75,3.75,0,0,0,8,12.66H8a4.87,4.87,0,0,0,.88-.88h0l.79-1A4.75,4.75,0,0,1,7.77,8,4,4,0,0,1,8.7,4.8Z'
          />
          <path
            class='cls-2'
            fill={color || '#343434'}
            d='M20,4.75c-.14-1.12-.79-2-2.25-3.18C16.56.68,15.31-.13,14,0A3.58,3.58,0,0,0,12,.78h0a5.31,5.31,0,0,0-.92.92l-.74,1a4.42,4.42,0,0,1,1.89,2.88,4,4,0,0,1-.92,3h0l-1.72,2.2c.19.17.39.32.59.48a9.19,9.19,0,0,0,1.6,1h0a3.58,3.58,0,0,0,2,.37A4.08,4.08,0,0,0,16.55,11h0l0,0L19,7.81l0,0h0A4.09,4.09,0,0,0,20,4.75Z'
          />
          <path
            class='cls-3'
            fill='transparent'
            // fill={color || '#d2d2d2'}
            d='M12.25,5.53a4.42,4.42,0,0,0-1.89-2.88L8.75,4.74,8.7,4.8A3.92,3.92,0,0,0,7.77,8a4.75,4.75,0,0,0,1.85,2.78l1.69-2.17,0,0h0A4,4,0,0,0,12.25,5.53Z'
          />
        </g>
      </g>
    </svg>
  )
}

// <defs><style>.cls-1{fill:#;}.cls-2{fill:#;}.cls-3{fill:#;}</style></defs>

export default PassLogo
