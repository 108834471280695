import React from 'react'
import { useAuth } from 'context/auth'
import AuthRoutes from './auth-routes'
import OnboardOrganisationRoutes from './onboard-organisation-routes'
import OrganisationRoutes from './organisation-routes'
import PublicRoutes from './public-routes'
import { ROLE } from 'enums/user'

const Routes = () => {
  const { currentUser } = useAuth()
  const routes = PublicRoutes

  // PUBLIC
  if (!currentUser) return routes.concat(AuthRoutes)

  // ONBOARD ORGANISATION
  if (!currentUser.account || currentUser.onBoardingStep < 3)
    return routes.concat(OnboardOrganisationRoutes)

  // EMAIL VERIFICATION
  // if (!currentUser.verified)
  // return EmailVerificationRoutes(currentUser.verificationEmailSent)

  // ORGANISATION ACCOUNT
  if (currentUser.role === ROLE.ADMIN) return routes.concat(OrganisationRoutes)
}

export default Routes
