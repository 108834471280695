import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CloseIcon from 'icons/close'

function Dialoguemodal({ open, onClose, title, children }) {
  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          className='tw-inset-0 tw-overflow-hidden tw-absolute tw-z-[1]'
          onClose={onClose}
        >
          <div>
            <div class='tw-absolute tw-inset-0 tw-overflow-hidden '>
              <Dialog.Overlay
                className='tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity tw-opacity-100'
                onClick={onClose}
              />
              <Transition.Child
                as={Fragment}
                enter='tw-transform tw-ease tw-duration-700'
                enterFrom='tw-translate-x-[450px]'
                enterTo=' tw-translate-x-0'
                leave='tw-transform tw-ease tw-duration-500'
                leaveFrom=' tw-translate-x-0'
                leaveTo='tw-translate-x-[450px]'
              >
                <div class='tw-fixed tw-inset-y-0 tw-right-0 tw-pl-10 tw-max-w-full tw-flex'>
                  <div class='tw-relative tw-w-screen tw-max-w-md tw-translate-x-0'>
                    <div class='tw-h-full tw-flex tw-flex-col tw-pb-6 tw-pt-3 tw-bg-white tw-shadow-xl tw-overflow-y-scroll'>
                      <div class='tw-relative tw-flex-1 tw-px-4 sm:tw-px-6'>
                        <div class='tw-flex tw-items-center tw-justify-between tw-pb-4 tw-pt-2'>
                          <button
                            class='tw-appearance-none tw-outline-none'
                            type='button'
                            tabindex='0'
                            onClick={onClose}
                          >
                            <span class='tw-sr-only'>Close panel</span>
                            <CloseIcon />
                          </button>
                        </div>
                        <h3 class='font-heading tw-text-xl md:tw-text-2xl tw-mb-4'>
                          {title}
                          {/* What details should this cardholder share? */}
                        </h3>
                        {children}
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default Dialoguemodal
