import React from 'react'
import { ErrorMessage, useFormikContext } from 'formik'

function CDInputOutline({
  label,
  name,
  placeholder,
  type,
  required,
  readOnly,
  onChange,
  value,
}) {
  const formik = useFormikContext()
  const props = formik && name ? formik.getFieldProps(name) : {}

  return (
    <div>
      <div class='tw-text-navyBlue tw-leading-regular tw-flex tw-cursor-default tw-items-center tw-gap-[5px] tw-text-[15px] md:tw-text-[13px] tw-justify-between'>
        <div class='tw-flex tw-items-center tw-gap-[5px]'>
          <div>
            {label}
            {required ? '*' : ''}
          </div>
        </div>
      </div>
      <input
        class='tw-block tw-text-regular tw-px-[10px] tw-py-[5px] tw-w-full tw-rounded-regular tw-border tw-border-lavender tw-focus:border-electricGreen tw-focus:ring-transparent tw-placeholder-lightGrey tw-leading-regular tw-resize-none tw-mt-[8px] tw-null'
        type={type || 'text'}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={readOnly}
        onChange={onChange}
        value={value}
        {...props}
      />
      {name && <ErrorMessage name={name} component='div' className='error' />}
    </div>
  )
}

export default CDInputOutline
