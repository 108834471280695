import React from 'react'

function RemoveIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      width='1em'
      height='1em'
      fill='#DA3D53'
      class='tw-w-5 tw-h-4'
    >
      <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z'></path>
    </svg>
  )
}

export default RemoveIcon
