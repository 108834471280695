const ACCOUNT_TYPE = {
  ORGANISATION: 'organisation',
  PERSONAL: 'personal',
  ENTERPRISE: 'enterprise',
}

const STATUS = {
  DOWNLOADED: 'downloaded',
  PASS_CREATED: 'pass_created',
}

const ROLE = {
  ADMIN: 'admin',
  POS: 'pos',
}

export { ACCOUNT_TYPE, STATUS, ROLE }
