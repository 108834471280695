import PassCover from 'icons/pass-cover'
import PassLogo from 'icons/pass-logo'
import { QRCodeCanvas } from 'qrcode.react'
import React from 'react'
import { invertTextColor, vcfText } from 'utils'

function GooglePass({ design }) {
  const {
    backgroundColor,
    logoImage,
    stripImage,
    jobTitle,
    prefix,
    firstName,
    lastName,
    fullName,
    email,
    phone,
    phones,
    organisationName,
    companyURL,
    linkedInURL,
    socials,
    addresses,
    personalAccount,
  } = design

  return (
    <div className='tw-relative' style={{ maxWidth: '330px' }}>
      <div
        className='tw-visible tw-w-[330px] tw-shadow-lg tw-text-white tw-rounded-[32px] tw-overflow-hidden'
        style={{ backgroundColor: backgroundColor }}
      >
        <div className='tw-flex tw-flex-col tw-h-full tw-justify-between tw-pb-6 tw-w-full'>
          <div className='tw-relative tw-mt-[16px] tw-mr-[16px] tw-ml-[16px]'>
            <div className='tw-mb-[16px]'>
              <div className='tw-flex tw-flex-row tw-items-center'>
                <div className='tw-mr-[16px]'>
                  <div className='tw-rounded-full tw-w-[40px] tw-m-0 tw-flex'>
                    {logoImage ? (
                      <img
                        class='even:tw-hidden'
                        src={logoImage}
                        alt='Logo'
                      ></img>
                    ) : (
                      <div
                        id='default-logo'
                        className='tw-block tw-w-full tw-m-1'
                      >
                        <PassLogo color={invertTextColor(backgroundColor)} />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    color: invertTextColor(backgroundColor),
                  }}
                ></div>
              </div>
              <div
                className='tw-text-[12px] tw-uppercase tw-mt-5'
                style={{
                  color: invertTextColor(backgroundColor),
                }}
              >
                {jobTitle}
              </div>
              {/* <div className='tw-flex tw-justify-between'>
                <p className='tw-text-1xl'>Customer Name:</p>
                <p>Points:</p>
              </div> */}
              <div
                className='tw-flex tw-justify-between tw-text-1xl tw-font-extralight'
                style={{
                  color: invertTextColor(backgroundColor),
                }}
              >
                {'Customer Name'}
                <div className='tw-text-1xl'> Points: </div>
              </div>
              <div
                className='tw-flex tw-justify-between tw-text-1xl tw-font-extralight'
                style={{
                  color: invertTextColor(backgroundColor),
                }}
              >
                {fullName}
                <div className='tw-text-1xl'> 0 </div>
              </div>
            </div>
            <div className='tw-mt-[12px] tw-mb-[12px]'>
              <div className='tw-bg-white tw-mt-5 tw-mx-auto tw-rounded-md tw-card-qrcode tw-max-w-fit tw-p-[16px] tw-rounded-[16px] tw-shadow-[0_4px_5px_0px_rgba(0,0,0,0.14)_0_1px_10px_0px_rgba(0,0,0,0.12)_0_2px_4px-1px_rgba(0,0,0,0.2)]'>
                <QRCodeCanvas
                  value={vcfText({
                    prefix,
                    firstName,
                    lastName,
                    email,
                    phone,
                    phones,
                    jobTitle,
                    organisationName,
                    companyURL,
                    linkedInURL,
                    socials,
                    addresses,
                    personalAccount,
                  })}
                  viewBox='0 0 53 53'
                  size={128}
                />
              </div>
              <p
                className='tw-card-qrcode-text tw-text-base tw-mt-[8px] tw-font-normal tw-text-center'
                style={{
                  color: invertTextColor(backgroundColor),
                }}
              >
                Save my contact details
              </p>
            </div>
          </div>
        </div>
        {stripImage ? (
          <div className='tw-w-full tw-overflow-hidden tw-strip-preview tw-h-[170px]'>
            <img
              class='even:tw-hidden '
              src={stripImage}
              alt='Primary strip'
            ></img>
          </div>
        ) : (
          <span
            id='default-strip'
            className='tw-flex tw-items-center tw-px-6 tw-w-full tw-aspect-[3/1]'
          >
            <div className='tw-mx-auto tw-w-2/3'>
              <PassCover color={invertTextColor(backgroundColor)} />
            </div>
          </span>
        )}
      </div>
    </div>
  )
}

export default GooglePass
