import React from 'react'

function TableHeader({ headers }) {
  return (
    <div class='tw-px-[10px] tw-hidden md:tw-flex tw-border-b-[1px] tw-border-[#E7E7E7] tw-pb-[10px]'>
      {headers.map((header) => (
        <div class='tw-text-[12px] tw-w-2/12 tw-flex tw-gap-[3px] tw-cursor-pointer'>
          {header}
        </div>
      ))}
    </div>
  )
}

export default TableHeader
