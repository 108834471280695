import React from 'react'
import Edit from './actions/edit'
import ResendEmail from './actions/resend-email'
import Delete from '../delete'
import { useAuth } from 'context/auth'

function QuickActionColumn({ user, ...props }) {
  const { currentUser } = useAuth()
  return (
    <div class='tw-w-2/12 tw-items-center tw-hidden md:tw-flex tw-overflow-hidden'>
      <ResendEmail user={user} />
      <Edit user={user} {...props} />
      {currentUser.account?.creator === user._id ? (
        ''
      ) : (
        <Delete user={user} {...props} />
      )}
    </div>
  )
}

export default QuickActionColumn
