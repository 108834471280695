import React from 'react'
import TableHeader from './table-header'
import TableData from './table-data'
import Loader from 'components/loader'

function Table({ headers = [], data = [], loading }) {
  return loading ? (
    <Loader />
  ) : (
    <div>
      <TableHeader headers={headers} />
      <TableData data={data} headers={headers} />
    </div>
  )
}

export default Table
