import React, { useState, useEffect, createContext, useContext } from 'react'
import Api from 'utils/api'
import * as XLSX from 'xlsx'

const initCardHoldersContextPropsState = {
  loading: true,
  users: [],
  setUsers: () => {},
  searchUser: () => {},
  downloadExcel: () => {},
  downloadCSV: () => {},
  filterUser: () => {},
  refreshList: () => {},
}

const CardHoldersContext = createContext(initCardHoldersContextPropsState)

const useCardHolders = () => {
  return useContext(CardHoldersContext)
}

const CardHoldersProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])

  const performFilter = (values, query) =>
    values.find((item) => item?.toLowerCase().includes(query.toLowerCase()))

  const searchUser = (e) => {
    const { value } = e.target
    const filtered = users.filter((user) =>
      performFilter([user.firstName, user.lastName, user.email], value)
    )
    setFilteredUsers([...filtered])
  }

  const filterUser = (filters) => {
    if (!filters.length) return setFilteredUsers([...users])
    const filtered = users.filter((item) => {
      return filters.includes(item.role) || filters.includes(item.department)
    })
    setFilteredUsers([...filtered])
  }

  const getSubscriber = async () => {
    const { response } = await Api('/pass/subscriber', 'get')
    setLoading(false)
    if (response) {
      setUsers(response.data)
      setFilteredUsers(response.data)
    }
  }

  const getData = () => {
    const data = [['First Name', 'Last Name', 'Email', 'Phone']]

    const user_data = users.map((user) => [
      user.userId?.firstName,
      user.userId?.lastName,
      user.email,
      user.phone?.toString(),
    ])

    data.push(...user_data)
    return data
  }

  const downloadExcel = () => {
    const data = getData()
    const workbook = XLSX.utils.book_new()
    const sheet = XLSX.utils.aoa_to_sheet(data)
    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1')
    return XLSX.writeFile(workbook, 'cardholders.xlsx')
  }

  const downloadCSV = () => {
    const data = getData()

    const csvContent = data.map((row) => row.join(',')).join('\n')
    const blob = new Blob([csvContent], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'cardholders.csv'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  const onLoad = () => {
    getSubscriber()
  }

  useEffect(onLoad, [])

  return (
    <CardHoldersContext.Provider
      value={{
        loading,
        users: filteredUsers || users,
        setUsers,
        searchUser,
        downloadExcel,
        downloadCSV,
        filterUser,
        refreshList: onLoad,
      }}
    >
      {children}
    </CardHoldersContext.Provider>
  )
}

export { CardHoldersProvider, useCardHolders }
