import { useCardHolders } from 'layouts/admin/team/team.context'
import { ACCOUNT_TYPE, ROLE } from 'enums/user'
import ClickOutSide from 'hooks/click-outside'
import AddIcon from 'icons/add'
import CloseIcon from 'icons/close'
import FilterIcon from 'icons/filter'
import { PLANS } from 'layouts/authentication/register/organisation/subscription/data/plans'
import React, { useState } from 'react'

function ListFilter({ list, title, handleSelect, isExist }) {
  return (
    <div>
      <div class='tw-whitespace-nowrap tw-text-[#959595] tw-text-[12px] tw-mb-[20px]'>
        {title}
      </div>
      <div class='tw-flex tw-flex-col tw-gap-[24px]'>
        {list.map((role, idx) => (
          <div
            key={role}
            class='tw-flex tw-items-center tw-text-[12px] tw-gap-[6px] tw-cursor-pointer'
            onClick={() => handleSelect({ name: role, value: role })}
          >
            {isExist(role) ? (
              <div
                class='tw-bg-electricGreen tw-flex tw-items-center tw-justify-center tw-rounded-[50%] tw-cursor-pointer'
                style={{ height: '12px' }}
              >
                <CloseIcon class='tw-fill-white tw-w-[12px] tw-h-[7px]' />
              </div>
            ) : (
              <div
                class='tw-bg-[#E7E7E7] tw-flex tw-items-center tw-justify-center tw-rounded-[50%] tw-cursor-pointer'
                style={{ height: '12px' }}
              >
                <AddIcon />
              </div>
            )}
            <div class='tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-capitalize'>
              {role}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

function UserRoleFilter(props) {
  return (
    <ListFilter
      list={Object.values(ROLE).filter((role) => role != ROLE.SUPER_ADMIN)}
      title={'User role'}
      {...props}
    />
  )
}

function AccountTypeFilter(props) {
  return (
    <ListFilter
      list={Object.values(ACCOUNT_TYPE)}
      title={'Account type'}
      {...props}
    />
  )
}

function PlanTypeFilter(props) {
  return (
    <ListFilter
      list={Object.values(PLANS).filter((item) => item != PLANS.CUSTOM)}
      title={'Plan'}
      {...props}
    />
  )
}

function Filters({
  onSelect,
  selectedFilters,
  removeFilter,
  showUserRole = true,
  showAccountType,
  showPlanType,
}) {
  const handleSelect = (selectedItem) => {
    const index = selectedFilters.findIndex(
      (item) => item.value == selectedItem.value
    )
    if (index < 0) return onSelect(selectedItem)
    removeFilter(index)
  }

  const isExist = (value) => selectedFilters.find((item) => item.value == value)

  return (
    <div class='tw-flex tw-flex-col tw-absolute tw-shadow tw-border-lavender tw-top-[25px] tw-bg-white tw-px-[18px] tw-py-[20px] tw-rounded-[8px] tw-w-[220px] tw-gap-[20px]'>
      {showUserRole && (
        <UserRoleFilter handleSelect={handleSelect} isExist={isExist} />
      )}
      {showAccountType && (
        <AccountTypeFilter handleSelect={handleSelect} isExist={isExist} />
      )}
      {showPlanType && (
        <PlanTypeFilter handleSelect={handleSelect} isExist={isExist} />
      )}
    </div>
  )
}

function SelectedFilters({ filters, removeFilter }) {
  return (
    <div class='tw-flex tw-overflow-hidden tw-gap-[18px]'>
      {filters.map((filter, idx) => (
        <div
          key={filter.value}
          class='tw-gap-[5px] tw-text-electricGreen tw-text-[13px] tw-capitalize tw-flex tw-items-center tw-shrink-0'
          onClick={() => removeFilter(idx)}
        >
          <div
            class='tw-bg-electricGreen tw-flex tw-items-center  tw-justify-center tw-rounded-[50%] tw-cursor-pointer'
            style={{ height: '12px' }}
          >
            <CloseIcon class='tw-fill-white tw-w-[12px] tw-h-[7px]' />
          </div>
          {filter.name}
        </div>
      ))}
    </div>
  )
}

function ClearButton({ ...props }) {
  return (
    <div
      {...props}
      class='tw-flex tw-shrink-0 tw-text-[#959595] tw-text-[13px] tw-items-center tw-cursor-pointer tw-mr-[18px]'
    >
      <CloseIcon class='tw-mr-1' />
      Clear filters
    </div>
  )
}

function FilterList({
  showUserRole,
  showAccountType,
  showPlanType,
  onSelect,
  onRemove,
  onClear,
}) {
  const { filterUser } = useCardHolders()
  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState([])

  const handleSelectedFilter = (selectedFilter) => {
    filters.push(selectedFilter)
    setFilters([...filters])
    filterUser(filters.map((item) => item.value))
    onSelect && onSelect(filters.map((item) => item.value))
  }

  const clearFilters = () => {
    setFilters([])
    setShowFilters(false)
    filterUser([])
    onClear && onClear()
  }

  const removeFilter = (idx) => {
    filters.splice(idx, 1)
    setFilters([...filters])
    filterUser(filters.map((item) => item.value))
    onRemove && onRemove(filters.map((item) => item.value))
  }
  return (
    <ClickOutSide onClickOutside={() => setShowFilters(false)}>
      <div class='tw-px-[10px] tw-py-[15px] tw-hidden md:tw-flex tw-min-w-0'>
        <div class='tw-flex tw-align-center'>
          <div class='tw-flex tw-relative tw-gap-[15px] tw-min-w-0'>
            <div
              onClick={() => setShowFilters(!showFilters)}
              class='tw-flex tw-items-center tw-gap-[5px] tw-text-electricGreen tw-text-regular tw-cursor-pointer tw-shrink-0'
            >
              <FilterIcon />
              <span>
                {filters.length > 0 && filters.length + ' '}Filter
                {filters.length > 1 && 's'}
              </span>
            </div>
            <div class='tw-flex tw-overflow-hidden tw-gap-[18px]'>
              {filters.length > 0 && (
                <SelectedFilters
                  filters={filters}
                  removeFilter={removeFilter}
                />
              )}
            </div>
            {filters.length > 0 && <ClearButton onClick={clearFilters} />}
            {showFilters && (
              <Filters
                onSelect={handleSelectedFilter}
                selectedFilters={filters}
                removeFilter={removeFilter}
                showUserRole={showUserRole}
                showAccountType={showAccountType}
                showPlanType={showPlanType}
              />
            )}
          </div>
        </div>
      </div>
    </ClickOutSide>
  )
}

export default FilterList
