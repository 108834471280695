import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react'

const initAdminLayoutContextPropsState = {
  openCamera: false,
  setOpenCamera: () => {},
}

const AdminLayoutContext = createContext(initAdminLayoutContextPropsState)

const useAdminLayout = () => {
  return useContext(AdminLayoutContext)
}

const AdminLayoutProvider = ({ children }) => {
  const [openCamera, setOpenCamera] = useState(false)

  return (
    <AdminLayoutContext.Provider
      value={{
        openCamera,
        setOpenCamera,
      }}
    >
      {children}
    </AdminLayoutContext.Provider>
  )
}

export { AdminLayoutProvider, useAdminLayout }
