import React from 'react'

function StatusColumn({ status }) {
  return (
    <div class='tw-w-2/12 tw-items-center tw-hidden md:tw-flex tw-overflow-hidden'>
      <div class='tw-text-[7px] tw-px-[7px] tw-py-[2px] tw-rounded-[3px] tw-flex tw-items-center tw-bg-[#D4FFE8] tw-text-[#8AB9A0]'>
        <span class='tw-whitespace-nowrap tw-capitalize'>{status}</span>
      </div>
    </div>
  )
}

export default StatusColumn
